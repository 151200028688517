import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-prices-table',
  templateUrl: './prices-table.component.html',
  styleUrls: ['./prices-table.component.css'],
})
export class PricesTableComponent {
  countries = ['España', 'UK', 'Francia'];

  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];

  assignerData: any = [];

  searchTerm: string = '';

  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any[];
  products!: any[];
  types!: any[];
  selectedDate: string = '#';
  selectedTypeClient: string = '#';
  selectedZone: string = '#';
  selectedProduct: string = '#';

  index: number = 0;
  length!: number;
  pageSize: number = 6;
  openedDiv: any = '#';
  readonly columns = [
    'vertical',
    'movi_b2c',
    'prov_b2c',
    'movi_b2b',
    'prov_b2b',
    'movi_pre',
    'prov_pre',
    'movi_hyb',
    'prov_hyb',
    'actions'
  ];

  readonly columns_tarifas = ['fees', 'price', 'actions'];

  verticalsTypes = [
    { value: 1, name: 'TRANSFER', realName: 'Transfer' },
    { value: 2, name: 'MECHANICAL_INSPECTION', realName: 'Taller' },
    { value: 3, name: 'VEHICLE_WASH', realName: 'Lavado' },
    { value: 4, name: 'REFUELING', realName: 'Repostaje' },
    { value: 5, name: 'PRE_TECHNICAL_INSPECTION', realName: 'Pre-ITV' },
    {
      value: 6,
      name: 'LONG_DISTANCE_TRANSFER',
      realName: 'Transfer Larga Distancia',
    },
    { value: 7, name: 'TECHNICAL_INSPECTION', realName: 'ITV' },
    { value: 8, name: 'VEHICLE_INSURANCE', realName: 'Seguro' },
    { value: 10, name: 'ACCESSORY', realName: 'Cafler Fresh' },
    { value: 11, name: 'VALET', realName: 'Valet' },
    { value: 12, name: 'TYRES', realName: 'Neumáticos' },
    { value: 13, name: 'FORMALITIES', realName: 'Trámites' },
    { value: 14, name: 'PARKING', realName: 'Parking' },
    {
      value: 15,
      name: 'REPLACEMENT_VEHICLE',
      realName: 'Vehículo de sustitución',
    },
    { value: 16, name: 'TOW_TRUCK', realName: 'Grúa' },
    { value: 17, name: 'WHATEVER_YOU_WANT', realName: 'Pide lo que quieras' },
  ];
    productsInfo:any = [
        {
            product: 'TRANSFER',
            productData: [
                {
                    subProduct:'Transfer 0 - 15Km',
                    p1:this.generateRandomValue(),
                    p2:this.generateRandomValue(),
                    p3:this.generateRandomValue(),
                    p4:this.generateRandomValue(),
                    p5:this.generateRandomValue(),
                    p6:this.generateRandomValue(),
                    p7:this.generateRandomValue(),
                    p8:this.generateRandomValue()
                },
                {
                    subProduct:'Transfer 15 - 30Km',
                    p1:this.generateRandomValue(),
                    p2:this.generateRandomValue(),
                    p3:this.generateRandomValue(),
                    p4:this.generateRandomValue(),
                    p5:this.generateRandomValue(),
                    p6:this.generateRandomValue(),
                    p7:this.generateRandomValue(),
                    p8:this.generateRandomValue()
                },
            ],
        },
        {
            product: 'LONG_DISTANCE_TRANSFER',
            productData: [
              {
                subProduct:'Transfer Larga Distancia 0 - 15Km',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct:'Transfer Larga Distancia 15 - 30Km',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'TECHNICAL_INSPECTION',
            productData: [
              {
                subProduct:'ITV 0 - 15Km',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct:'ITV 15 - 30Km',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'VEHICLE_WASH',
            productData: [
              {
                subProduct: 'Lavado tunnel exterior',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct: 'Lavado Integral',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'PRE_TECHNICAL_INSPECTION',
            productData: [
              {
                subProduct: 'PreITV',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct: 'Lavado Integral',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'PARKING',
            productData: [
              {
                subProduct: 'PreITV',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct: 'Lavado Integral',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'VALET',
            productData: [
              {
                subProduct: 'PreITV',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct: 'Lavado Integral',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'REPLACEMENT_VEHICLE',
            productData: [
              {
                subProduct: 'PreITV',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct: 'Lavado Integral',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'VEHICLE_INSURANCE',
            productData: [
              {
                subProduct: 'PreITV',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct: 'Lavado Integral',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
        {
            product: 'WHATEVER_YOU_WANT',
            productData: [
              {
                subProduct: 'PreITV',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
              {
                subProduct: 'Lavado Integral',
                p1:this.generateRandomValue(),
                p2:this.generateRandomValue(),
                p3:this.generateRandomValue(),
                p4:this.generateRandomValue(),
                p5:this.generateRandomValue(),
                p6:this.generateRandomValue(),
                p7:this.generateRandomValue(),
                p8:this.generateRandomValue()
              },
            ],
        },
    ]

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  open = false;
  selected = null;
  activeZone: any;
  activeEditPrice: any;
  priceToEdit: any;
  activeEditProduct: any;
  priceToEditb2c1: any;
  priceToEditb2c2: any;
  priceToEditb2b1: any;
  priceToEditb2b2: any;
  priceToEditpre1: any;
  priceToEditpre2: any;
  priceToEdithyb1: any;
  priceToEdithyb2: any;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }
    bsModalRef?: BsModalRef;
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private router: Router,
    public hs: HistoryServicesService
  ) {}

    ngOnInit(): void {
        this.hs.getZones().subscribe((res: any) => {
            this.zones = res.zones;
        });
    }

    showDepositAlert(): void {
        this.alerts
        .open('Se han guardado los cambios realizados en los precios aplciables!', {
            label: 'Se han guardado los cambios',
            status: 'success',
            autoClose: true,
        }).subscribe();
    }

    filterData() {
        let data = {
            zoneId: this.activeZone,
            verticalType: '',
            chassisType: '',
            fuelType: '',
            includeToppings: false,
        };

        this.hs.getProducts(data).subscribe((products: any) => {
            this.products = products.products;

            this.products.forEach((item: any) => {
                item.p1 = this.generateRandomValue();
                item.p2 = this.generateRandomValue();
                item.p3 = this.generateRandomValue();
                item.p4 = this.generateRandomValue();
                item.p5 = this.generateRandomValue();
                item.p6 = this.generateRandomValue();
                item.p7 = this.generateRandomValue();
            });
        });
    }

    getCountProducts(type: any): any {
        if (this.products) {
            let cp = this.products.find((pro) => pro.verticalType == type);
            if (cp != undefined) {
                return this.products.filter((pro) => pro.verticalType == type)?.length;
            }
            return 0;
        }
    }

    getVerticalProducts(type: any): any {
        if (this.products) {
            let cp = this.products.find((pro) => pro.verticalType == type);
            if (cp != undefined) {
                return this.products.filter((pro) => pro.verticalType == type);
            }
            return [];
        }
    }

    getTipoDeDato(valor: any): string {
        if (valor === null) {
          return 'null';
        }
        return typeof valor;
    }

    generateRandomValue(): string {
        const randomValue = (Math.random() * 50).toFixed(2);
        const [integer, decimal] = randomValue.split('.');
        const formattedInteger = integer.padStart(2, '0');
        return `${formattedInteger}.${decimal}`;
    }

    setOpenedDiv(value: any) {
        this.openedDiv = value;
    }

    closeOpenedDiv(value: any) {
        this.openedDiv = 0;
    }

    openModal(serviceDataModal: TemplateRef<void>, type: any, price:any) {
        if(type == 'fix'){
            this.activeEditPrice = 'Hora fija';
        }else{
            this.activeEditPrice = 'Fee de urgencia';
        }

        this.priceToEdit = price;
        this.bsModalRef = this.modalService.show(serviceDataModal);
    }

    openModalProduct(editProductModal: TemplateRef<void>, product: any) {
        this.activeEditProduct = product.subProduct;

        this.priceToEditb2c1 = product.p1;
        this.priceToEditb2c2 = product.p2;
        this.priceToEditb2b1 = product.p3;
        this.priceToEditb2b2 = product.p4;
        this.priceToEditpre1 = product.p5;
        this.priceToEditpre2 = product.p6;
        this.priceToEdithyb1 = product.p7;
        this.priceToEdithyb2 = product.p8;
        this.bsModalRef = this.modalService.show(editProductModal);
    }

    getVerticalRealName(name:any){
        let vf = this.verticalsTypes.find((vr) => vr.name == name);
        return vf ? vf.realName : '';
    }

    saveModal(){
        this.bsModalRef?.hide();
        this.showDepositAlert();
    }
}
