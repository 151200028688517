import { Component, ChangeDetectionStrategy, Inject, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TuiAlertService } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';

@Component({
    selector: 'app-modal-add-event-or-incidence',
    templateUrl: './modal-add-event-or-incidence.component.html',
    styleUrls: ['./modal-add-event-or-incidence.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class ModalAddEventOrIncidenceComponent {
    // modalRef?: BsModalRef;
    constructor(
      @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
      private fb: FormBuilder,
      private offcanvasService: NgbOffcanvas,
      public hs: HistoryServicesService,
      private ngZone: NgZone,
      private translate: TranslateService,
    ) {}
}