import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  NgZone,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  NgControl,
} from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import {
  Observable,
  from,
  Subject,
  Subscription,
  single,
  firstValueFrom,
} from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { TuiDay } from '@taiga-ui/cdk';
import {TranslateService} from '@ngx-translate/core';
declare var moment: any;
declare var google: any;
@Component({
  selector: 'app-modal-duplicate-service',
  templateUrl: './modal-duplicate-service.component.html',
  styleUrls: ['./modal-duplicate-service.component.css'],
})
export class ModalDuplicateServiceComponent {
  @ViewChild('originAddress', { static: true }) originAddressInput!: ElementRef;
  @ViewChild('destinationAddress', { static: true })
  destinationAddressInput!: ElementRef;
  @Input() service_info: any;
  @Input() base_info: any;
  @Input() type_info: any;

  timeType!: any;
  singleTime: string = '#';
  show_load_modal: any;
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    public hs: HistoryServicesService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {}
  @Input() formData: any;
  verticals2: any[] = [
    // { name: "Desconocido", type: "UNKNOWN" },
    { name: 'Transfer', type: 'TRANSFER' },
    { name: 'Taller', type: 'MECHANICAL_INSPECTION' },
    { name: 'Lavado', type: 'VEHICLE_WASH' },
    { name: 'Repostaje', type: 'REFUELING' },
    { name: 'Pre-ITV', type: 'PRE_TECHNICAL_INSPECTION' },
    { name: 'Transfer Larga Distancia', type: 'LONG_DISTANCE_TRANSFER' },
    { name: 'ITV', type: 'TECHNICAL_INSPECTION' },
    { name: 'Seguro', type: 'VEHICLE_INSURANCE' },
    { name: 'Cafler Fresh', type: 'ACCESSORY' },
    { name: 'Valet', type: 'VALET' },
    { name: 'Neumáticos', type: 'TYRES' },
    { name: 'Trámites', type: 'FORMALITIES' },
    { name: 'Parking', type: 'PARKING' },
    { name: 'Vehículo de sustitución', type: 'REPLACEMENT_VEHICLE' },
    { name: 'Grúa', type: 'TOW_TRUCK' },
    { name: 'Pide lo que quieras', type: 'WHATEVER_YOU_WANT' },
  ];

  contactForm = this.fb.group({
    zoneId: [null],
    zone: [null],
    originAddress: ['', Validators.required],
    originAddressDetails: [''],
    provider: null,
    originAddressGeopoint: [
      {
        Longitude: 0,
        Latitude: 0,
        Srid: 0,
      },
      Validators.required,
    ],
    originAddressGeopointLt: [0, Validators.required],
    originAddressGeopointLn: [0, Validators.required],
    singleTime: ['Hora fija'],
    originContactData: [
      {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        isExternalContact: '',
      },
      Validators.required,
    ],
    destinationAddress: ['', Validators.required],
    destinationAddressDetails: '',
    destinationAddressGeopoint: [
      {
        Longitude: 0,
        Latitude: 0,
        Srid: 0,
      },
      Validators.required,
    ],
    destinationAddressGeopointLt: [0, Validators.required],
    destinationAddressGeopointLn: [0, Validators.required],
    workdayStartTime: [''],
    workdayEndTime: [''],
    destinationContactData: [
      {
        name: '',
        phoneNumber: '',
        emailAddress: '',
        isExternalContact: '',
      },
      Validators.required,
    ],
    vehicleData: [
      {
        vehicleType: '',
        brand: '',
        model: '',
        licensePlate: '',
        fuelType: '',
      },
      Validators.required,
    ],
    transferType: '',
    serviceStartDateInput: [''],
    serviceStartDate: '',
    serviceEndDate: '',
    serviceStartHour: [''],
    serviceEndHour: '',
    serviceAssignedHour: '',
    serviceAssignedDate: '',
    comments: '',
    bookedProducts: [
      {
        productId: '' /*,
            providerId: "",
            serviceConfiguration: ""*/,
      },
    ],
    isOriginContactExternal: false,
    isDestinationContactExternal: false,
    sameContactOriginDestiny: false,
    originContactName: [null, Validators.required],
    origingContactPhoneNumber: [null, Validators.required],
    originContactEmailAddress: [null, Validators.required],
    destinationContactName: [null, Validators.required],
    destinationContactPhoneNumber: [null, Validators.required],
    destinationContactEmailAddress: [null, Validators.required],
    VehicleType: [-1],
    VehicleTypeName: ['', Validators.required],
    VehicleBrand: [null, Validators.required],
    VehicleModel: [null, Validators.required],
    licensePlate: [null, Validators.required],
    VehicleFuel: [-1],
    VehicleFuelName: [''],
    product: ['', Validators.required],
    vertical: [''],
    AppointmentCode: '',
    IsInspectionPaid: false,
    VehicleFuelType: '',
    MonetaryAmount: '',
    VehicleStatus: '',
    Width: null,
    Series: null,
    Diameter: null,
    SpeedIndex: null,
    LoadIndex: null,
    Season: null,
    IsAntiPuncture: false,
    FavoriteBrand: null,
    Model: null,
    RangeType: null,
    NumberOfTyres: null,
    TyresPermutation: false,
    TyresAlignment: false,
    TyresPrice: null,
    LabourCosts: null,
    PermutationPrice: null,
    AlignmentPrice: null,
    FreeTaxTyresPrice: null,
    FreeTaxLabourCosts: null,
    FreeTaxPermutationPrice: null,
    FreeTaxAlignmentPrice: null,
    sameOriginAddress: false,
    clientName: {
        userId:'',
        businessName:''
    },
    clientId: null,
  });

  providers = [];
  providersName: any = [];
  providersNameAlt: any = [
    'Cafler fixed Refueling location',
    'Cafler fixed Pre-ITV',
    'Cafler fixed Maintenance location',
  ];
  clients = [];
  operatives = [];
  subproducts = [];
  products = [];
  productsName: any = [];
  productsOfVertical: any = [];

  VehicleChassisType = [
    // {
    //     value:0,
    //     name:'UNDEFINED',
    //     real_name:'Desconocido'
    // },
    {
      value: 1,
      name: 'SEDAN',
      real_name: 'Turismo',
    },
    {
      value: 2,
      name: 'MINIVAN',
      real_name: 'Monovolumen',
    },
    {
      value: 3,
      name: 'ATV',
      real_name: 'Todoterreno',
    },
    {
      value: 4,
      name: 'LIGHT_VAN',
      real_name: 'Furgoneta ligera',
    },
    {
      value: 5,
      name: 'VAN',
      real_name: 'Furgoneta',
    },
    {
      value: 6,
      name: 'BOX_TRUCK',
      real_name: 'Furgón',
    },
    {
      value: 20,
      name: 'SCOOTER',
      real_name: 'Moto (hasta 125cc)',
    },
    {
      value: 21,
      name: 'MOTORBIKE',
      real_name: 'Moto +125cc',
    },
  ];

  VehicleChassis = [
    // 'Desconocido',
    'Turismo',
    'Monovolumen',
    'Todoterreno',
    'Furgoneta ligera',
    'Furgoneta',
    'Furgón',
    'Moto (hasta 125cc)',
    'Moto +125cc',
  ];

  VehicleFuelType = [
    // {
    //     value:0,
    //     name:'NA',
    //     real_name:'Desconocido'
    // },
    {
      value: 1,
      name: 'FUEL_95',
      real_name: 'Gasolina 95',
    },
    {
      value: 2,
      name: 'FUEL_98',
      real_name: 'Gasolina 98',
    },
    {
      value: 3,
      name: 'DIESEL',
      real_name: 'Diesel e+',
    },
    {
      value: 4,
      name: 'DIESEL_PREMIUM',
      real_name: 'Diesel 10e+',
    },
    {
      value: 5,
      name: 'HYBRID',
      real_name: 'Híbrido',
    },
    {
      value: 6,
      name: 'ELECTRIC',
      real_name: 'Eléctrico',
    },
    {
      value: 7,
      name: 'NATURAL_GAS',
      real_name: 'Gas',
    },
    {
      value: 8,
      name: 'FUEL_95E10',
      real_name: 'Gasolina 95 e10',
    },
    {
      value: 20,
      name: 'OTHER',
      real_name: 'Otro',
    },
  ];

  VehicleFuel = [
    // 'Desconocido',
    'Gasolina 95',
    'Gasolina 98',
    'Diesel e+',
    'Diesel 10e+',
    'Híbrido',
    'Eléctrico',
    'Gas',
    'Gasolina 95 e10',
    'Otro',
  ];

  VehicleFuelAlt = [
    // 'Desconocido',
    'Gasolina 95',
    'Gasolina 98',
    'Diesel e+',
    'Diesel 10e+',
  ];

  transferType = [
    {
      value: 0,
      name: 'ON_ROAD',
    },
    {
      value: 1,
      name: 'INDIVIDUAL_TOW_TRUCK',
    },
    {
      value: 2,
      name: 'COLLECTIVE_TOW_TRUCK',
    },
  ];

  transfers = ['ON_ROAD', 'INDIVIDUAL_TOW_TRUCK', 'COLLECTIVE_TOW_TRUCK'];

  monetarys = ['5€', '10€', '20€', '50€', '80€'];

  vehicle_status = [
    'El vehículo arranca y puede rodar correctamente',
    'El vehículo no arranca pero puede rodar correctamente',
    'El vehículo no arranca y no funciona correctamente',
  ];

  clientRealList = [
    {
      userId: '2272B5FF-07D3-4008-BA39-9894CC9B3EDA',
      businessName: 'Linkcar Automoció SL',
    },
    // {
    //   id: '64C0B370-A8DF-4879-8212-472C3A07777C',
    //   name: 'RNO Bymycar Barcelona',
    // },
    // {
    //   id: '6023AAB5-B176-48CD-AD06-30213135F0BA',
    //   name: 'Moto reparación SL',
    // },
    // {
    //   id: '21691C16-DCFE-421D-8EE7-466E0764A6FB',
    //   name: 'Mastertrac de Automoción SA (1)',
    // },
    // {
    //   id: '466492CF-4455-4742-AC9B-239E2AC08372',
    //   name: 'AUTOVIVO',
    // },
    // {
    //   id: 'CEA9ECDB-839A-4F94-8820-1A793DC865FF',
    //   name: 'Maquina Motors S.A.U Terrassa',
    // },
    // {
    //   id: '3AA57588-39AF-407D-8C9D-022AAF44D479',
    //   name: 'Màquina Motors SAU (Barcelona)',
    // },
    // {
    //   id: '95E99EBE-103D-4CFC-924A-E4DDC26EE0BE',
    //   name: 'Proceran 2016 SL',
    // },
    // {
    //   id: '7F61D26E-F414-4D97-87B2-7BCD2D442749',
    //   name: 'Anteveni S.L',
    // },
    // {
    //   id: 'FB3FF53F-AB14-4B8B-87DD-F27E3159AD49',
    //   name: 'COVESA MOLLET',
    // },
    // {
    //   id: 'BA3782FC-4674-495C-B93E-4F33D6F7375F',
    //   name: 'Centro Avanzado Reparaciones Car, SLU (Linea Directa)',
    // },
    // {
    //   id: '7147979F-1650-49D0-8AAC-DC52114330F1',
    //   name: 'Vespa Balart SAU (2)',
    // },
    // {
    //   id: 'D80B48CE-776A-4759-8FA2-361E97A40447',
    //   name: 'IMECAT, S.A.',
    // },
    // {
    //   id: 'B1BB9E6A-8464-4BD0-9B54-7EB63D0A99A9',
    //   name: 'RNO Bymycar Barcelona (1)',
    // },
    // {
    //   id: '987754E2-9305-4E05-8FAC-8D2413C6B30F',
    //   name: 'RNO Bymycar Barcelona (2)',
    // },
    // {
    //   id: '6AE6BEEB-6BFD-4D69-9718-1AC93C5C3206',
    //   name: 'Tesla SL (7)',
    // },
    // {
    //   id: '6C3CC80B-FF7A-4671-A029-C49EE7B5EEC8',
    //   name: 'Masterclas de Automoción SA (3)',
    // },
    // {
    //   id: '57088C38-B6C4-4EB4-BEAA-9BE273D5DB14',
    //   name: 'OK MOBILITY (Baleares)',
    // },
    // {
    //   id: 'aaef076d-f2a8-4e42-accd-ca28eae8cd92',
    //   name: 'Test Cafler Barcelona',
    // },
  ];

  clientsList = [
    'Linkcar Automoció SL',
    'RNO Bymycar Barcelona',
    'Moto reparación SL',
    'Mastertrac de Automoción SA (1)',
    'AUTOVIVO',
    'Maquina Motors S.A.U Terrassa',
    'Màquina Motors SAU (Barcelona)',
    'Proceran 2016 SL',
    'Anteveni S.L',
    'COVESA MOLLET',
    'Centro Avanzado Reparaciones Car, SLU (Linea Directa)',
    'Vespa Balart SAU (2)',
    'IMECAT, S.A.',
    'RNO Bymycar Barcelona (1)',
    'RNO Bymycar Barcelona (2)',
    'Tesla SL (7)',
    'Masterclas de Automoción SA (3)',
    'OK MOBILITY (Baleares)',
    'Test Cafler Barcelona',
  ];

  readonly stringifyNameclient = (item: { businessName: string }): string =>
    `${item.businessName}`;

  verticals: any[] = [];

  timeTypes = ['Hora fija', 'Franja horaria'];

  verticalsTypes = [
    // { value: 0, name: "UNKNOWN", realName: "Desconocido" },
    { value: 1, name: 'TRANSFER', realName: 'Transfer' },
    { value: 2, name: 'MECHANICAL_INSPECTION', realName: 'Taller' },
    { value: 3, name: 'VEHICLE_WASH', realName: 'Lavado' },
    { value: 4, name: 'REFUELING', realName: 'Repostaje' },
    { value: 5, name: 'PRE_TECHNICAL_INSPECTION', realName: 'Pre-ITV' },
    {
      value: 6,
      name: 'LONG_DISTANCE_TRANSFER',
      realName: 'Transfer Larga Distancia',
    },
    { value: 7, name: 'TECHNICAL_INSPECTION', realName: 'ITV' },
    { value: 8, name: 'VEHICLE_INSURANCE', realName: 'Seguro' },
    { value: 10, name: 'ACCESSORY', realName: 'Cafler Fresh' },
    { value: 11, name: 'VALET', realName: 'Valet' },
    { value: 12, name: 'TYRES', realName: 'Neumáticos' },
    { value: 13, name: 'FORMALITIES', realName: 'Trámites' },
    { value: 14, name: 'PARKING', realName: 'Parking' },
    {
      value: 15,
      name: 'REPLACEMENT_VEHICLE',
      realName: 'Vehículo de sustitución',
    },
    { value: 16, name: 'TOW_TRUCK', realName: 'Grúa' },
    { value: 17, name: 'WHATEVER_YOU_WANT', realName: 'Pide lo que quieras' },
  ];
  hourStart: any;
  openSelect = false;
  openElement = '';
  hours = tuiCreateTimePeriods(
    undefined,
    undefined,
    [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 59,
    ]
  );
  hours2: any = [];
  readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];

  countryIsoCode = TuiCountryIsoCode.ES;
  zones: any;
  active_configuration: any;
  content = '';
  show_provider: any;
  isDisabledTypeCar: boolean = true;

  ngAfterViewInit(): void {
    if (!google || !google.maps || !google.maps.places) {
      console.error('Google Maps API no está cargada correctamente.');
      return;
    }

    const input = this.originAddressInput.nativeElement;
    const input2 = this.destinationAddressInput.nativeElement;

    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: [] // Sin restricciones de tipo (lugares, direcciones, etc.)
    });
    const autocomplete2 = new google.maps.places.Autocomplete(input2, {
      types: [] // Sin restricciones de tipo (lugares, direcciones, etc.)
    });

    // const autocomplete = new google.maps.places.SearchBox(input);
    // const autocomplete2 = new google.maps.places.SearchBox(input2);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        console.log(place.name+' '+place.formatted_address);
        if (!place.geometry) {
          console.error('No se encontró la geometría del lugar.');
          return;
        }

        let formattedAddress = place.formatted_address;
        if(place.name){
            formattedAddress = place.name+', '+place.formatted_address
        }

        if (place.geometry) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          this.contactForm.patchValue({
            originAddressGeopointLt: lat,
            originAddressGeopointLn: lng,
            originAddress: formattedAddress,
          });

          this.contactForm.patchValue({
            originAddressGeopoint: {
              Latitude: lat,
              Longitude: lng,
              Srid: 4326,
            },
          });

          if (this.contactForm.value.sameOriginAddress) {
            this.contactForm.patchValue({
              destinationAddressGeopointLt: lat,
              destinationAddressGeopointLn: lng,
              destinationAddress: formattedAddress,
            });

            this.contactForm.patchValue({
              destinationAddressGeopoint: {
                Latitude: lat,
                Longitude: lng,
                Srid: 4326,
              },
            });
          }
        }
      });
    });

    autocomplete2.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete2.getPlace();
        // const place2 = autocomplete.getPlaces();
        // const place = place2[0];
        
        if (!place.geometry) {
          console.error('No se encontró la geometría del lugar.');
          return;
        }
        let formattedAddress = place.formatted_address;
        if(place.name){
            formattedAddress = place.name+', '+place.formatted_address
        }
        if (place.geometry) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          this.contactForm.patchValue({
            destinationAddressGeopointLt: lat,
            destinationAddressGeopointLn: lng,
            destinationAddress: formattedAddress,
          });

          this.contactForm.patchValue({
            destinationAddressGeopoint: {
              Latitude: lat,
              Longitude: lng,
              Srid: 4326,
            },
          });
        }
      });
    });
  }

  getVertical(verticalName: any) {
    const selectedVertical = this.verticals.find(
      (vertical) => vertical.realName === verticalName
    );
    return selectedVertical ? selectedVertical.name : '';
  }

  getVerticalValue(verticalName: any) {
    const selectedVertical = this.verticalsTypes.find(
      (vertical) => vertical.realName === verticalName
    );
    return selectedVertical ? selectedVertical.value : 'ss';
  }

  getVerticalByType(verticalType: any) {
    const selectedVertical = this.verticalsTypes.find(
      (vertical) => vertical.value === verticalType
    );
    // console.log(selectedVertical);
    return selectedVertical ? selectedVertical.realName : '';
  }

  getVerticalToTraduction(verticalName: any) {
    const selectedVertical = this.verticalsTypes.find(
      (vertical) => vertical.realName === verticalName
    );
    // console.log(selectedVertical);
    return selectedVertical ? selectedVertical.name : '';
  }

  onTimeInput(input:any): void {
    let timeControl = this.contactForm.value.workdayStartTime;
    if(input == 'end'){
        timeControl = this.contactForm.value.workdayEndTime;
    }
    if(input == 'hour'){
        timeControl = this.contactForm.value.serviceStartHour;
    }
    if (timeControl !== null && timeControl !== undefined) {
        let value = timeControl.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

        // Formatear el valor en HH:MM
        if (value.length >= 3) {
            value = `${value.slice(0, 2)}:${value.slice(2, 4)}`;
        }

        if(value.length >= 5){
            if (value && /^([01]\d|2[0-3]):([0-5]\d)$/.test(value)) {
                const [hours, minutes] = value.split(':').map(Number);
                if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
                    if(input == 'start'){
                        this.contactForm.patchValue({
                            workdayStartTime: value
                        });
                    }
                    if(input == 'end'){
                        this.contactForm.patchValue({
                            workdayEndTime: value
                        });
                    }
                    if(input == 'hour'){
                        this.contactForm.patchValue({
                            serviceStartHour: value
                        });
                    }
                }else{
                    if(input == 'start'){
                        this.contactForm.patchValue({
                            workdayStartTime: null
                        });
                    }
                    if(input == 'end'){
                        this.contactForm.patchValue({
                            workdayEndTime: null
                        });
                    }
                    if(input == 'hour'){
                        this.contactForm.patchValue({
                            serviceStartHour: null
                        });
                    }
                }
            }else{
                if(input == 'start'){
                    this.contactForm.patchValue({
                        workdayStartTime: null
                    });
                }
                if(input == 'end'){
                    this.contactForm.patchValue({
                        workdayEndTime: null
                    });
                }
                if(input == 'hour'){
                    this.contactForm.patchValue({
                        serviceStartHour: null
                    });
                }
            }
        }
    }
  }

  async ngOnInit(): Promise<void> {
    this.hs.getZones().subscribe((zones: any) => {
      this.operatives = zones.zones.map((zone: any) => zone.zoneName);
      this.zones = zones.zones;
      this.contactForm.patchValue({
        zone: this.getZoneById(this.service_info?.zoneId)?.zoneName,
      });
    });

    this.hs.getClients().subscribe((res: any) => {
        this.clientRealList = res.businessUserData;

        if(this.service_info){
            var cf = this.clientRealList.find((item:any) => item.userId === this.service_info.clientId);
            if(cf){
                this.contactForm.patchValue({
                    clientName: cf,
                });
            }
        }
    });

    await this.contactForm.get('zone')!.valueChanges.subscribe((value) => {
      if (value) {
        this.openElement = '';
        const zoneId = this.getZone(value);
        if (!this.service_info) {
          this.contactForm.patchValue({
            zoneId: zoneId.zoneId,
            vertical: null,
            product: null,
          });
        }
        
        this.hs.getProviders(this.getZone(value)).subscribe((providers: any) => {
             if(!this.service_info){
                this.providersName = providers.providersInZone.map(
                  (provider: any) => provider.name
                );
             }
            this.providers = providers.providersInZone;
        });
        this.getProducts();
      }
    });

    await this.contactForm.get('vertical')?.valueChanges.subscribe((value) => {
      if (value) {
        this.productsOfVertical.forEach((value: any, key: any) => {
          value.traduction = this.hs.translate(value.productName);
        });
        if (!this.service_info) {
          this.contactForm.patchValue({ product: null });
        }
        const verticalTypeObject = this.verticalsTypes.find(
          (v) => v.realName === value
        );
        if (verticalTypeObject) {
          const selectedVerticalType = verticalTypeObject.value;
          this.productsName = this.productsOfVertical
            .filter(
              (product: any) => product.verticalType === selectedVerticalType
            )
            .map((product: any) => product.traduction);
        }
      }

        this.active_configuration = value;
        this.contactForm.patchValue({
            provider: null,
            product: null,
        });
    });

    await this.contactForm.get('product')?.valueChanges.subscribe((value) => {
      if (value) {
        const idProduct = this.getProductIdByTra(value);
        if (idProduct) {
            console.log(idProduct, 444);
            if(this.providers.length < 1){
                this.hs.getProviders(this.getZone(this.contactForm.value.zone)).subscribe((providers: any) => {
                     if(!this.service_info){
                        this.providersName = providers.providersInZone.map(
                          (provider: any) => provider.name
                        );
                     }
                    this.providers = providers.providersInZone;

                    this.ngZone.run(() => {
                          this.providersName = this.providers
                            .filter((pro: any) => pro.productIds.includes(idProduct))
                            .map((pro: any) => pro.name);
                            this.show_provider = this.getProductRequestProviderByid(idProduct);
                    });
                });
            }else{
                this.ngZone.run(() => {
                      this.providersName = this.providers
                        .filter((pro: any) => pro.productIds.includes(idProduct))
                        .map((pro: any) => pro.name);
                        this.show_provider = this.getProductRequestProviderByid(idProduct);
                });
            }
        }
      }
    });

    this.contactForm
      .get('workdayStartTime')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.hourStart = value;
          this.hours2 = tuiCreateTimePeriods(
            this.hourStart.hours + 1,
            undefined,
            [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
              35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
              51, 52, 53, 54, 55, 56, 57, 58, 59,
            ]
          );
        }
      });

    this.contactForm
      .get('sameContactOriginDestiny')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.fiellSameContactInfo();
        }
      });

    this.contactForm
      .get('isOriginContactExternal')
      ?.valueChanges.subscribe((value) => {
        if (this.contactForm.value.sameContactOriginDestiny) {
          this.contactForm.patchValue({
            isDestinationContactExternal: value,
          });
        }
      });

    this.contactForm
      .get('originContactName')
      ?.valueChanges.subscribe((value) => {
        if (this.contactForm.value.sameContactOriginDestiny) {
          this.contactForm.patchValue({
            destinationContactName: value,
          });
        }
      });

    this.contactForm
      .get('origingContactPhoneNumber')
      ?.valueChanges.subscribe((value) => {
        if (this.contactForm.value.sameContactOriginDestiny) {
          this.contactForm.patchValue({
            destinationContactPhoneNumber: value,
          });
        }
      });

    this.contactForm
      .get('originContactEmailAddress')
      ?.valueChanges.subscribe((value) => {
        if (this.contactForm.value.sameContactOriginDestiny) {
          this.contactForm.patchValue({
            destinationContactEmailAddress: value,
          });
        }
      });

    if (this.type_info != 'ad') {
      if (this.service_info) {
        if (
          this.service_info.originContactInformation?.address ==
            this.service_info.destinationContactInformation?.address &&
          this.service_info.originContactInformation.addressPoint?.Longitude ==
            this.service_info.destinationContactInformation.addressPoint
              ?.Longitude &&
          this.service_info.originContactInformation.addressPoint?.Latitude ==
            this.service_info.destinationContactInformation.addressPoint
              ?.Latitude &&
          this.service_info.originContactInformation?.addressDetails ==
            this.service_info.destinationContactInformation?.addressDetails
        ) {
          this.contactForm.patchValue({
            sameOriginAddress: true,
          });
        }

        if (
          this.service_info?.originContactInformation.name ==
            this.service_info?.destinationContactInformation.name &&
          this.service_info?.originContactInformation.phoneNumber ==
            this.service_info?.destinationContactInformation.phoneNumber &&
          this.service_info?.originContactInformation.emailAddress ==
            this.service_info?.destinationContactInformation.emailAddress &&
          this.service_info?.originContactInformation.isExternalContact ==
            this.service_info?.destinationContactInformation.isExternalContact
        ) {
          this.contactForm.patchValue({
            sameContactOriginDestiny: true,
          });
        }

        if (
          this.service_info.serviceDates.servicePickupDateRangeStart?.seconds ==
          this.service_info.serviceDates.servicePickupDateRangeEnd?.seconds
        ) {
            this.hs.getZones().subscribe((zones: any) => {
                let zones2 = zones.zones;
                let zoneId = this.service_info.zoneId;
                const zone = zones2.find((zone:any) => zone.zoneId === zoneId);
                const dateInClientZone = moment.unix(
                    this.service_info.serviceDates.servicePickupDateRangeStart?.seconds).tz(zone.timeZone);
                var sh = dateInClientZone.format('HH:mm:').split(':');

                this.contactForm.patchValue({
                    singleTime: 'Hora fija',
                    serviceStartHour: this.findTimeObject(
                      parseInt(sh[0]),
                      parseInt(sh[1])
                    ),
                });
            });
          // console.log(sh, this.findTimeObject(parseInt(sh[0]), parseInt(sh[1])), parseInt(sh[0]), parseInt(sh[1]));
        } else {
            this.hs.getZones().subscribe((zones: any) => {
                let zones2 = zones.zones;
                let zoneId = this.service_info.zoneId;
                const zone = zones2.find((zone:any) => zone.zoneId === zoneId);

                const dateInClientZone = moment.unix(
                    this.service_info.serviceDates.servicePickupDateRangeStart?.seconds).tz(zone.timeZone);
                var sh = dateInClientZone.format('HH:mm:').split(':');

                const dateInClientZone2 = moment.unix(
                    this.service_info.serviceDates.servicePickupDateRangeEnd?.seconds).tz(zone.timeZone);
                var eh = dateInClientZone2.format('HH:mm:').split(':');

                this.contactForm.patchValue({
                    singleTime: 'Franja horaria',
                    workdayStartTime: this.findTimeObject(
                      parseInt(sh[0]),
                      parseInt(sh[1])
                    ),
                    workdayEndTime: this.findTimeObject(
                      parseInt(eh[0]),
                      parseInt(eh[1])
                    ),
                });

                this.hourStart = this.findTimeObject(
                    parseInt(sh[0]),
                    parseInt(sh[1])
                );
                this.hours2 = tuiCreateTimePeriods(
                    this.hourStart.hours + 1,
                    undefined,
                    [
                      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                      19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
                      35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
                      51, 52, 53, 54, 55, 56, 57, 58, 59,
                    ]
                );
            });
        }

        if(this.service_info.serviceDates.serviceAssignedPickupDate){
            this.hs.getZones().subscribe((zones: any) => {
                let zones2 = zones.zones;
                let zoneId = this.service_info.zoneId;
                const zone = zones2.find((zone:any) => zone.zoneId === zoneId);
                const dateInClientZone = moment.unix(
                    this.service_info.serviceDates.serviceAssignedPickupDate?.seconds).tz(zone.timeZone);
                var ah = dateInClientZone.format('HH:mm:').split(':');

                 this.contactForm.patchValue({
                    serviceAssignedHour: this.findTimeObject(
                      parseInt(ah[0]),
                      parseInt(ah[1])
                    ),
                });
            });
        }

        if (this.service_info?.products[0].serviceConfiguration) {
          let ser_con = JSON.parse(
            this.service_info?.products[0].serviceConfiguration
          );
          let vs;
          let ua = 0;
          if (Object.keys(ser_con).length > 0) {
            Object.entries(ser_con).forEach(([key, value]) => {
              // console.log(`Índice: ${key}, Valor: ${value}`);
              if (key == 'VehicleStatus') {
                if (value == 0) {
                  vs = 'El vehículo arranca y puede rodar correctamente';
                } else if (value == 1) {
                  vs = 'El vehículo no arranca pero puede rodar correctamente';
                } else {
                  vs = 'El vehículo no arranca y no funciona correctamente';
                }

                // this.contactForm.patchValue({
                //   VehicleStatus: vs,
                // });
                ser_con['VehicleStatus'] = vs;
              } else if (key == 'FuelType') {
                let ft = this.getFuelTypeByValue(value)?.['real_name'];
                // this.contactForm.patchValue({
                //   VehicleFuelType: ft,
                // });
                ser_con['FuelType'] = ft;
                ser_con['VehicleFuelType'] = ft;
              } else if (key == 'MonetaryAmount'){
                    // this.contactForm.patchValue({
                    //   MonetaryAmount: '10'
                    // });
                  ser_con['MonetaryAmount'] = value+'€';
              }
            });
              this.contactForm.patchValue(ser_con);
          }
        }

        await this.contactForm.patchValue({
          // zoneId: this.service_info?.zoneId,
          originAddress: this.service_info.originContactInformation?.address
            ? this.service_info.originContactInformation?.address
            : '',
          originContactName: this.service_info?.originContactInformation.name,
          origingContactPhoneNumber:
            this.service_info?.originContactInformation.phoneNumber,
          originContactEmailAddress:
            this.service_info?.originContactInformation.emailAddress,
          isOriginContactExternal:
            this.service_info?.originContactInformation.isExternalContact,
          destinationAddress: this.service_info.destinationContactInformation
            ?.address
            ? this.service_info.destinationContactInformation?.address
            : '',
          destinationContactName:
            this.service_info?.destinationContactInformation.name,
          destinationContactPhoneNumber:
            this.service_info?.destinationContactInformation.phoneNumber,
          destinationContactEmailAddress:
            this.service_info?.destinationContactInformation.emailAddress,
          isDestinationContactExternal:
            this.service_info?.destinationContactInformation.isExternalContact,
          // VehicleTypeName: this.getTypeChassisByType(
          //   this.service_info.vehicleData.vehicleType
          // ),
          VehicleType: this.getVehicleType(
            this.service_info.vehicleData.vehicleType
          ),
          VehicleBrand: this.service_info?.vehicleData.brand,
          VehicleModel: this.service_info?.vehicleData.model,
          licensePlate: this.service_info?.vehicleData.licensePlate,
          VehicleFuel: this.service_info?.vehicleData.fuelType,
          // VehicleFuelName: this.getFuelTypeEdit(
          //   this.service_info?.vehicleData.fuelType
          // )?.['real_name'],

          originAddressGeopoint: {
            Longitude: this.service_info.originContactInformation.addressPoint
              ?.Longitude
              ? this.service_info.originContactInformation.addressPoint
                  ?.Longitude
              : 0,
            Latitude: this.service_info.originContactInformation.addressPoint
              .Latitude
              ? this.service_info.originContactInformation.addressPoint.Latitude
              : 0,
            Srid: this.service_info.originContactInformation.addressPoint.Srid
              ? this.service_info.originContactInformation.addressPoint.Srid
              : 0,
          },
          destinationAddressDetails:
            this.service_info.destinationContactInformation.addressDetails,
          originAddressDetails:
            this.service_info.originContactInformation.addressDetails,

          originAddressGeopointLt: this.service_info.originContactInformation
            .addressPoint.Latitude
            ? this.service_info.originContactInformation.addressPoint.Latitude
            : 0,
          originAddressGeopointLn: this.service_info.originContactInformation
            .addressPoint?.Longitude
            ? this.service_info.originContactInformation.addressPoint?.Longitude
            : 0,
          originContactData: {
            name: this.service_info?.originContactInformation.name,
            phoneNumber: this.service_info?.originContactInformation.name,
            emailAddress:
              this.service_info?.originContactInformation.emailAddress,
            isExternalContact:
              this.service_info?.originContactInformation.isExternalContact,
          },
          destinationAddressGeopoint: {
            Longitude: this.service_info.destinationContactInformation
              .addressPoint?.Longitude
              ? this.service_info.destinationContactInformation.addressPoint
                  ?.Longitude
              : 0,
            Latitude: this.service_info.destinationContactInformation
              .addressPoint?.Latitude
              ? this.service_info.destinationContactInformation.addressPoint
                  ?.Latitude
              : 0,
            Srid: this.service_info.destinationContactInformation.addressPoint
              ?.Srid
              ? this.service_info.destinationContactInformation.addressPoint
                  ?.Srid
              : 0,
          },
          destinationAddressGeopointLt: this.service_info
            .destinationContactInformation.addressPoint?.Latitude
            ? this.service_info.destinationContactInformation.addressPoint
                ?.Latitude
            : 0,
          destinationAddressGeopointLn: this.service_info
            .destinationContactInformation.addressPoint?.Longitude
            ? this.service_info.destinationContactInformation.addressPoint
                ?.Longitude
            : 0,
          destinationContactData: {
            name: this.service_info?.destinationContactInformation.name,
            phoneNumber: this.service_info?.destinationContactInformation.name,
            emailAddress:
              this.service_info?.destinationContactInformation.emailAddress,
            isExternalContact:
              this.service_info?.destinationContactInformation
                .isExternalContact,
          },
          vehicleData: {
            vehicleType: this.service_info.vehicleData.vehicleType,
            brand: this.service_info.vehicleData.brand,
            model: this.service_info.vehicleData.model,
            licensePlate: this.service_info.vehicleData.licensePlate,
            fuelType: this.service_info.vehicleData.fuelType,
          },
          transferType: 'COLLECTIVE_TOW_TRUCK',
          serviceStartDateInput: await this.getDateFull(
            this.service_info.serviceDates.servicePickupDateRangeStart?.seconds,
            this.service_info.zoneId
          ),
          comments: this.service_info.clientComments,
        });
      }
    }

    this.contactForm
      .get('sameOriginAddress')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.contactForm.patchValue({
            destinationAddressGeopointLt:
              this.contactForm.value.originAddressGeopointLt,
            destinationAddressGeopointLn:
              this.contactForm.value.originAddressGeopointLn,
            destinationAddress: this.contactForm.value.originAddress,
            destinationAddressDetails:
              this.contactForm.value.originAddressDetails,
          });

          this.contactForm.patchValue({
            destinationAddressGeopoint: {
              Latitude: this.contactForm.value.originAddressGeopointLt ?? 0,
              Longitude: this.contactForm.value.originAddressGeopointLn ?? 0,
              Srid: 4326,
            },
          });
        }
      });

    this.contactForm
      .get('originAddressDetails')
      ?.valueChanges.subscribe((value) => {
        if (this.contactForm.value.sameOriginAddress) {
          this.contactForm.patchValue({
            destinationAddressDetails: value,
          });
        }
      });

    this.contactForm.get('VehicleTypeName')?.valueChanges.subscribe((value) => {
        if(value){
            this.getProducts2();
        }
    });

    this.contactForm.get('VehicleFuelName')?.valueChanges.subscribe((value) => {
        if(value){
            this.getProducts2();
        }
    });

    if(this.type_info == 'ed'){
        this.contactForm.get('clientName')?.disable();
        this.contactForm.get('VehicleTypeName')?.disable();
        this.contactForm.get('VehicleFuelName')?.disable();
        this.contactForm.get('provider')?.disable();
    }
  }

  getProducts() {
        if(this.service_info){
            if(this.getTypeChassisByType(this.service_info.vehicleData.vehicleType)){
                this.contactForm.patchValue({
                  VehicleTypeName: this.getTypeChassisByType(this.service_info.vehicleData.vehicleType)
                });
            }
            if(this.getFuelTypeEdit(this.service_info?.vehicleData.fuelType)){
                this.contactForm.patchValue({
                    VehicleFuelName:this.getFuelTypeEdit(
                    this.service_info?.vehicleData.fuelType
                  )?.['real_name']
                });
            }
        }
    let data = {
      zoneId: this.getZone(this.contactForm.get('zone')?.value).zoneId,
      verticalType: this.getVertical(this.contactForm.get('vertical')?.value),
      chassisType: this.getTypeChassis(
        this.contactForm.get('VehicleTypeName')?.value
      ),
      fuelType: this.getFuelTypeByName(
        this.contactForm.get('VehicleFuelName')?.value
      )?.['name'],
      includeToppings: false,
    };
    this.hs.getProducts(data).subscribe((products: any) => {
      this.productsOfVertical = products.products;
      const uniqueVerticalRealNames = new Set(
        products.products.map((product: any) => {
          const verticalTypeObject = this.verticalsTypes.find(
            (v) => v.value === product.verticalType
          );
          return verticalTypeObject ? verticalTypeObject.realName : null;
        })
      );
      const uniqueVerticalRealNamesArray = Array.from(
        uniqueVerticalRealNames
      ).filter((name) => name !== null);
      this.verticals = uniqueVerticalRealNamesArray;
         // console.log(this.verticals, products.products, this.productsOfVertical);
      if (this.service_info) {
        let pinfo = this.getProductIdById(
          this.service_info.products[0]?.productId
        );
        if(pinfo){
            this.contactForm.patchValue({
              vertical: this.getVerticalByType(pinfo?.verticalType),
              product: this.hs.translate(
                this.getProductIdById(this.service_info.products[0]?.productId)
                  .productName
              ),
            });

            this.ngZone.run(() => {
                this.providersName = this.providers
                .filter((pro: any) => pro.productIds.includes(pinfo.productId))
                .map((pro: any) => pro.name);

                // console.log(pinfo,this.providersName, 54545);
            });
        }else{
            this.contactForm.patchValue({
              vertical: null,
              product: null,
              // VehicleTypeName: null,
              // VehicleFuelName: null,
            });
        }
      }
    });
  }

  getProducts2() {
    let data = {
      zoneId: this.getZone(this.contactForm.get('zone')?.value).zoneId,
      verticalType: this.getVertical(this.contactForm.get('vertical')?.value),
      chassisType: this.getTypeChassis(
        this.contactForm.get('VehicleTypeName')?.value
      ),
      fuelType: this.getFuelTypeByName(
        this.contactForm.get('VehicleFuelName')?.value
      )?.['name'],
      includeToppings: false,
    };
    this.hs.getProducts(data).subscribe((products: any) => {
      this.productsOfVertical = products.products;
      const uniqueVerticalRealNames = new Set(
        products.products.map((product: any) => {
          const verticalTypeObject = this.verticalsTypes.find(
            (v) => v.value === product.verticalType
          );
          return verticalTypeObject ? verticalTypeObject.realName : null;
        })
      );
      const uniqueVerticalRealNamesArray = Array.from(
        uniqueVerticalRealNames
      ).filter((name) => name !== null);
      this.verticals = uniqueVerticalRealNamesArray;
            if (this.contactForm.value.vertical) {
                this.productsOfVertical.forEach((value: any, key: any) => {
                  value.traduction = this.hs.translate(value.productName);
                });
                if (!this.service_info) {
                  this.contactForm.patchValue({ product: null });
                }
                const verticalTypeObject = this.verticalsTypes.find(
                  (v) => v.realName === this.contactForm.value.vertical
                );
                if (verticalTypeObject) {
                  const selectedVerticalType = verticalTypeObject.value;
                  this.productsName = this.productsOfVertical
                    .filter(
                      (product: any) => product.verticalType === selectedVerticalType
                    )
                    .map((product: any) => product.traduction);
                }
            }
    });
  }

  getFormValidationErrors(formGroup: FormGroup): { [key: string]: any } {
    const formErrors: { [key: string]: any } = {};
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control && control.invalid && control.errors) {
        formErrors[controlName] = control.errors;
      }
    });

    return formErrors;
  }

  findTimeObject(hours: number, minutes: number): any {
    const hoursComplete = tuiCreateTimePeriods(
      0,
      24,
      [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 58, 59,
      ]
    );
    return hoursComplete.find(
      (time) => time.hours === hours && time.minutes === minutes
    );
  }

  private clickCounts: { [key: string]: number } = {};
  onSelectClick(event: MouseEvent) {
    const selectElement = event.target as HTMLSelectElement;
    const selectId = selectElement.id;

    if (!this.clickCounts[selectId]) {
      this.clickCounts[selectId] = 0;
    }
    this.clickCounts[selectId]++;

    if (this.clickCounts[selectId] === 1) {
      setTimeout(() => {
        const sourceElement = document.querySelector('tui-dropdown');
        const targetElement = document.getElementById(
          'modal_duplicate_service'
        );
        if (sourceElement && targetElement) {
          const clonedNode = sourceElement.cloneNode(true);
          // targetElement!.innerHTML = '';
          targetElement.appendChild(sourceElement);
        }
      }, 300);
    } else {
      if (this.clickCounts[selectId] === 2) {
        if (document.activeElement === selectElement) {
          selectElement.blur();
          // console.log(`Focus quitado del select ${selectId} por segundo clic`);
        }
        this.clickCounts[selectId] = 0;
      }
    }
  }

  resetFormSelects() {
    const entries = Object.entries(this.clickCounts);
    for (const [selectId, count] of entries) {
      this.clickCounts[selectId] = 0;
    }
  }

  onSelectBlur(type: any) {
    if (!type) {
      this.resetFormSelects();
    }
  }

  fiellSameContactInfo() {
    this.contactForm.patchValue({
      isDestinationContactExternal:
        this.contactForm.value.isOriginContactExternal,
      destinationContactName: this.contactForm.value.originContactName,
      destinationContactPhoneNumber:
        this.contactForm.value.origingContactPhoneNumber,
      destinationContactEmailAddress:
        this.contactForm.value.originContactEmailAddress,
    });
  }

  closeCanvas() {
    this.offcanvasService.dismiss();
  }

  hideLoaderModal() {
    const div = document.querySelector(
      '.loader_inside_modal_button'
    ) as HTMLElement;
    if (div) {
      div.style.display = 'none'; // Agrega display: flex al div
    }

    setTimeout(() => {
      const closeDiv = document.querySelector('.t-close');
      if (closeDiv) {
        (closeDiv as HTMLElement).click();
      }
    }, 5000);
  }

  showLoaderModal() {
    const div = document.querySelector(
      '.loader_inside_modal_button'
    ) as HTMLElement;
    if (div) {
      div.style.display = 'flex'; // Agrega display: flex al div
    }
  }

  showErrorMsj() {
    setTimeout(() => {
      const sourceElement = document.querySelector('tui-alert');
      const targetElement = document.getElementById(
        'modal_duplicate_service_alert'
      );
      if (sourceElement && targetElement) {
        // console.log('replicar')
        const clonedNode = sourceElement.cloneNode(true);
        // targetElement!.innerHTML = '';
        targetElement.appendChild(sourceElement);
      }
    }, 300);
  }

    saveContactInfo() {
        const form: any = { ...this.contactForm.value };

        form.originContactData = {
            name: this.contactForm.get('originContactName')?.value,
            phoneNumber: this.contactForm.get('origingContactPhoneNumber')?.value,
            emailAddress: this.contactForm.get('originContactEmailAddress')?.value,
            isExternalContact: this.contactForm.get('isOriginContactExternal')?.value,
        };

        if (this.contactForm.get('sameContactOriginDestiny')?.value) {
            form.destinationContactData = {
                name: this.contactForm.get('originContactName')?.value,
                phoneNumber: this.contactForm.get('origingContactPhoneNumber')?.value,
                emailAddress: this.contactForm.get('originContactEmailAddress')?.value,
                isExternalContact: this.contactForm.get('isOriginContactExternal')
                  ?.value,
            };
        } else {
            form.destinationContactData = {
                name: this.contactForm.get('destinationContactName')?.value,
                phoneNumber: this.contactForm.get('destinationContactPhoneNumber')
                  ?.value,
                emailAddress: this.contactForm.get('destinationContactEmailAddress')
                  ?.value,
                isExternalContact: this.contactForm.get('isDestinationContactExternal')
                  ?.value,
            };
        }

        form.vehicleData = {
            vehicleType: this.getTypeChassis(
                this.contactForm.get('VehicleTypeName')?.value
            ),
            brand: this.contactForm.get('VehicleBrand')?.value,
            model: this.contactForm.get('VehicleModel')?.value,
            licensePlate: this.contactForm.get('licensePlate')?.value,
            fuelType: this.getFuelTypeByName(
                this.contactForm.get('VehicleFuelName')?.value
            )?.['name'],
        };

        let sc;
        if (form.vertical == 'ITV') {
            sc = {
                AppointmentCode: form.AppointmentCode,
                IsInspectionPaid: form.IsInspectionPaid,
            };
            if (this.getProvider(form.provider)?.['id']) {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    providerId: this.getProvider(form.provider)?.['id'],
                    serviceConfiguration: JSON.stringify(sc),
                }];
            } else {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    serviceConfiguration: JSON.stringify(sc),
                }];
            }
        } else if (form.vertical == 'Repostaje') {
            let price = form.MonetaryAmount.replace(/[^\d.-]/g, '');
            let floatPrice = parseFloat(price);
            floatPrice = Math.round(floatPrice * 100) / 100;
            sc = {
                FuelType: this.getFuelTypeByName(form.VehicleFuelType)?.['value'],
                MonetaryAmount: floatPrice,
            };
            if (this.getProvider(form.provider)?.['id']) {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    providerId: this.getProvider(form.provider)?.['id'],
                    serviceConfiguration: JSON.stringify(sc),
                }];
            } else {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    serviceConfiguration: JSON.stringify(sc),
                }];
            }
        } else if (form.vertical == 'Neumáticos') {
            sc = {
                Width: form.Width.toString(),
                SpeedIndex: form.SpeedIndex.toString(),
                LoadIndex: form.LoadIndex.toString(),
                Season: form.Season.toString(),
                FavoriteBrand: form.FavoriteBrand.toString(),
                Model: form.Model.toString(),
                RangeType: form.RangeType.toString(),
                NumberOfTyres: form.NumberOfTyres,
                IsAntiPuncture: form.IsAntiPuncture,
                TyresPermutation: form.TyresPermutation,
                TyresAlignment: form.TyresAlignment,
                TyresPrice: form.TyresPrice,
                LabourCosts: form.LabourCosts,
            };
            if (this.getProvider(form.provider)?.['id']) {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    serviceConfiguration: JSON.stringify(sc),
                    providerId: this.getProvider(form.provider)?.['id'],
                }];
            } else {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    serviceConfiguration: JSON.stringify(sc),
                }];
            }
        } else if (form.vertical == 'Grúa') {
            let nd;
            if (
                form.VehicleStatus == 'El vehículo arranca y puede rodar correctamente'
            ){
                nd = 0;
            }else if(
                form.VehicleStatus ==
                'El vehículo no arranca pero puede rodar correctamente'
            ){
                nd = 1;
            }else if(
                form.VehicleStatus == 'El vehículo no arranca y no funciona correctamente'
            ) {
                nd = 2;
            }
            sc = {
                VehicleStatus: nd,
            };
            if (this.getProvider(form.provider)?.['id']) {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    serviceConfiguration: JSON.stringify(sc),
                    providerId: this.getProvider(form.provider)?.['id'],
                }];
            } else {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    serviceConfiguration: JSON.stringify(sc),
                }];
            }
        } else {
            if (this.getProvider(form.provider)?.['id']) {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                    providerId: this.getProvider(form.provider)?.['id'],
                }];
            } else {
                form.bookedProducts = [{
                    productId: this.getProductIdByTra(form.product),
                }];
            }
        }

        if(form.singleTime == 'Hora fija'){
            form.serviceStartDate = {
                seconds: this.timeToNotUnix(form.serviceStartHour),
            };
            form.serviceEndDate = {
                seconds: this.timeToNotUnix(form.serviceStartHour),
            };
        }else{
            form.serviceStartDate = {
                seconds: this.timeToNotUnix(form.workdayStartTime),
            };
            form.serviceEndDate = {
                seconds: this.timeToNotUnix(form.workdayEndTime),
            };
        }

        form.transferType = 'COLLECTIVE_TOW_TRUCK';
        if (this.contactForm.value.clientName) {
            form.clientId = this.contactForm.value.clientName?.['userId'];
        }else{
            form.clientId = '2272B5FF-07D3-4008-BA39-9894CC9B3EDA';
        }
        form.zoneId = this.getZone(this.contactForm.value.zone).zoneId;
        console.log(form); 

        this.showLoaderModal();
        if (this.type_info == 'du') {
          console.log(form);
          this.hs.addService(form).subscribe(
            (res: any) => {
              if (!res.wasSuccessful && res?.statusCode) {
                this.hs.showErrorCode(res.statusCode);
                this.showErrorMsj();
              } else {
                this.offcanvasService.dismiss();
                this.showDepositAlert();
              }
              this.hideLoaderModal();
            },
            (error) => {
              this.hideLoaderModal();
              if (error.status === 500) {
                this.alerts
                  .open('Problema en el servidor', {
                    label: 'Error 500',
                    status: 'error',
                    autoClose: false,
                  })
                  .subscribe();
                this.showErrorMsj();
              } else {
                this.alerts
                  .open(error, {
                    label: 'Otro error',
                    status: 'error',
                    autoClose: false,
                  })
                  .subscribe();
                this.showErrorMsj();
              }
            }
          );
        }

        if (this.type_info == 'ad') {
            this.hs.addService(form).subscribe(
                (res: any) => {
                    if (!res.wasSuccessful && res?.statusCode) {
                        this.hs.showErrorCode(res.statusCode);
                        this.showErrorMsj();
                    } else {
                        this.offcanvasService.dismiss();
                        this.showDepositAlert();
                    }    
                    this.hideLoaderModal();
                },
                (error) => {
                    this.hideLoaderModal();
                    if (error.status === 500) {
                        this.alerts
                          .open('Problema en el servidor', {
                            label: 'Error 500',
                            status: 'error',
                            autoClose: false,
                          })
                          .subscribe();
                        this.showErrorMsj();
                    } else {
                        this.alerts
                          .open(error, {
                            label: 'Otro error',
                            status: 'error',
                            autoClose: false,
                          })
                          .subscribe();
                        this.showErrorMsj();
                    }
                }
            );
          // console.log(form);
        }

        if (this.type_info == 'ed') {
            var tpp: any = [];
            this.service_info.products.forEach((item: any) => {
                if (item.isTopping) {
                    let td;
                    if (item.serviceConfiguration) {
                        td = {
                            productId: item.productId,
                            serviceConfiguration: item.serviceConfiguration,
                        };
                    } else {
                        td = {
                          productId: item.productId,
                        };
                    }
                    tpp.push(td);
                }
            });

          // let hoursi =
          //   form.workdayStartTime != ''
          //     ? form.workdayStartTime
          //     : form.serviceStartHour;
          // let hoursf =
          //   form.workdayStartTime != '' ? form.workdayEndTime : form.serviceEndHour;

            let ocd = {
                isExternalContact: this.contactForm.get('isOriginContactExternal')
                  ?.value,
                name: this.contactForm.get('originContactName')?.value,
                phoneNumber: this.contactForm.get('origingContactPhoneNumber')?.value,
                emailAddress: this.contactForm.get('originContactEmailAddress')?.value,
                address: this.contactForm.get('originAddress')?.value,
                addressPoint: {
                  Longitude: this.contactForm.get('originAddressGeopointLn')?.value,
                  Latitude: this.contactForm.get('originAddressGeopointLt')?.value,
                  Srid: 4326,
                },
                addressDetails: this.contactForm.get('originAddressDetails')?.value,
            };

            let dcd = {
                isExternalContact: this.contactForm.get('isDestinationContactExternal')
                  ?.value,
                name: this.contactForm.get('destinationContactName')?.value,
                phoneNumber: this.contactForm.get('destinationContactPhoneNumber')
                  ?.value,
                emailAddress: this.contactForm.get('destinationContactEmailAddress')
                  ?.value,
                address: this.contactForm.get('destinationAddress')?.value,
                addressPoint: {
                  Longitude: this.contactForm.get('destinationAddressGeopointLn')
                    ?.value,
                  Latitude: this.contactForm.get('destinationAddressGeopointLt')?.value,
                  Srid: 4326,
                },
                addressDetails: this.contactForm.get('destinationAddressDetails')
                  ?.value,
            };
            let data;
            if (Object.keys(tpp).length > 0) {
                data = {
                    orderHash: this.service_info.orderHash,
                    clientId: form.clientId,
                    originContactData: ocd,
                    destinationContactData: dcd,
                    vehicleData: form.vehicleData,
                    serviceStartDate: form.serviceStartDate,
                    serviceEndDate: form.serviceEndDate,
                    comments: this.contactForm.value.comments,
                };
            } else {
                data = {
                    orderHash: this.service_info.orderHash,
                    clientId: form.clientId,
                    originContactData: ocd,
                    destinationContactData: dcd,
                    vehicleData: form.vehicleData,
                    serviceStartDate: form.serviceStartDate,
                    serviceEndDate: form.serviceEndDate,
                    comments: this.contactForm.value.comments,
                };
            }

            this.hs.updateService(data).subscribe(
                (res: any) => {
                    if (!res.wasSuccessful && res?.statusCode) {
                        this.hs.showErrorCode(res.statusCode);
                        this.showErrorMsj();
                    } else {
                        this.offcanvasService.dismiss();
                        this.hs.publish('updateListPendingServices');
                        this.hs.publish('updateDetailsService');
                        this.showDepositAlert();
                    }
                    this.hideLoaderModal();
                },
                (error: any) => {
                    this.hideLoaderModal();
                    if (error.status === 500) {
                        this.alerts
                          .open('Problema en el servidor', {
                            label: 'Error 500',
                            status: 'error',
                            autoClose: false,
                          })
                          .subscribe();
                        this.showErrorMsj();
                    } else {
                        this.alerts
                          .open(error, {
                            label: 'Otro error',
                            status: 'error',
                            autoClose: false,
                          })
                          .subscribe();
                        this.showErrorMsj();
                    }
                }
            );
        }
    }

  getClientId(client_name: any) {
    const selectClient = this.clientRealList?.find(
      (client: any) => client.name === client_name
    );
    return selectClient ? selectClient : null;
  }

  replaceText(
    originalString: any,
    searchValue: string,
    replaceValue: string
  ): string {
    var new_text = originalString.replace(
      new RegExp(searchValue, 'g'),
      replaceValue
    );
    var f_date = new_text.split('/');
    return f_date[2] + '/' + f_date[1] + '/' + f_date[0];
  }

  showDepositAlert(): void {
    if (this.type_info == 'du') {
      this.alerts
        .open('Se ha duplicado correctamente', {
          label:
            'Se ha duplicado el servicio con ID ' +
            this.service_info.orderHash +
            ', para ' +
            this.contactForm.value.zone +
            ' a ' +
            this.replaceText(
              this.contactForm.value.serviceStartDateInput,
              '-',
              '/'
            ),
          status: 'success',
          autoClose: false,
        })
        .subscribe();
    }

    if (this.type_info == 'ed') {
      this.alerts
        .open('Se ha actualizado de manera correcta', {
          label:
            'Se ha actualizado la informacion sobre el servicio con el orderHash ' +
            this.service_info.orderHash,
          status: 'success',
          autoClose: false,
        })
        .subscribe();
    }

    if (this.type_info == 'ad') {
      this.alerts
        .open('Se ha guardado de manera correcta', {
          label: 'Se ha guardado la informacion sobre el servicio',
          status: 'success',
          autoClose: false,
        })
        .subscribe();
    }
  }

  searchDiv(element: any) {
    if (element != this.openElement) {
      // console.log('hay');
      let div = document.getElementsByTagName('tui-dropdown');
      if (div[0] != undefined) {
        // console.log(div[0]);
        let content_div = document.getElementById('modal_duplicate_service');
        let cdiv = content_div as HTMLElement;
        cdiv.innerHTML = '';
        cdiv.append(div[0]);
      }
      this.openElement = element;
    } else {
      this.openElement = '';
    }
  }

  searchDiv2() {
    setTimeout(() => {
      let div = document.getElementsByClassName(
        'ng-trigger-tuiDropdownAnimation'
      );
      // console.log(div);
      if (div.length > 0) {
        if (div[0] != undefined) {
          let content_div = document.getElementById('modal_duplicate_service');
          let cdiv = content_div as HTMLElement;
          cdiv.innerHTML = '';
          cdiv.append(div[0]);
        }
      }
      this.openElement = '';
    }, 400);
  }

  getProvider(providerName: any) {
    const selectedPro = this.providers?.find(
      (provider: any) => provider.name === providerName
    );
    return selectedPro ? selectedPro : null;
  }

  getZone(zoneName: any) {
    const selectedZone = this.zones?.find(
      (zone: any) => zone.zoneName === zoneName
    );
    return selectedZone;
  }

  getZoneById(zoneId: any) {
    const selectedZone = this.zones?.find(
      (zone: any) => zone.zoneId === zoneId
    );
    return selectedZone;
  }

  getVehicleType(vehicleName: any) {
    const selectedVehicle = this.VehicleChassisType.find(
      (type: any) => type.name === vehicleName
    );
    return selectedVehicle?.value;
  }

  getTypeChassis(vehicleName: any) {
    const selectedVehicle = this.VehicleChassisType.find(
      (type: any) => type.real_name === vehicleName
    );
    return selectedVehicle?.name;
  }

  getTypeChassisByType(vehicleName: any) {
    const selectedVehicle = this.VehicleChassisType.find(
      (type: any) => type.name === vehicleName
    );
    return selectedVehicle?.real_name;
  }

  getFuelType(fuelName: string) {
    const selectedFuel = this.VehicleFuelType.find(
      (type: any) => type.name === fuelName
    );
    return selectedFuel?.value;
  }

  getFuelTypeEdit(fuelName: any) {
    const selectedFuel = this.VehicleFuelType.find(
      (type: any) => type.name === fuelName
    );
    return selectedFuel;
  }

  getFuelTypeByName(fuelName: any) {
    const selectedFuel = this.VehicleFuelType.find(
      (type: any) => type.real_name === fuelName
    );
    return selectedFuel;
  }

  getFuelTypeByValue(fuelvalue: any) {
    const selectedFuel = this.VehicleFuelType.find(
      (type: any) => type.value === fuelvalue
    );
    return selectedFuel;
  }

  formatTimeUnit(unit: any) {
    return unit < 10 ? `0${unit}` : unit;
  }

  async getDateFull(seconds: any, zoneId: any) {
    const zones: any = await firstValueFrom(this.hs.getZones());

    const zone = zones.zones.find((zone: any) => zone.zoneId === zoneId);

    const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);
    console.log(dateInClientZone);

    return dateInClientZone.format('YYYY-MM-DD');
  }

  getDateHour(seconds: any) {
    const date = new Date(seconds * 1000);

    const hour = this.formatTimeUnit(date.getHours());
    const min = this.formatTimeUnit(date.getMinutes());

    return `${hour}:${min}`;
    // this.hs.getZones().subscribe((zones: any) => {
    //     let zones2 = zones.zones;
    //     let zoneId = this.service_info.zoneId;
    //     const zone = this.zones.find((zone:any) => zone.zoneId === zoneId);
    //     const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);
    //     return dateInClientZone.format('HH:mm');
    // });

  }

  timeToUnix(timeObject: any) {
    const today = moment(this.contactForm.value?.serviceStartDateInput).format(
      'DD/MM/YYYY'
    );
    const dateTimeString = `${today} ${timeObject.hours}:${timeObject.minutes}:${timeObject.seconds}.${timeObject.ms}`;
    return moment.utc(dateTimeString, 'DD/MM/YYYY HH:mm:ss.SSS').unix();
  }



  timeToNotUnix(timeObject: any) {
    var hours = this.formatTimeUnit(timeObject.hours);
    var minutes = this.formatTimeUnit(timeObject.minutes);
    var seconds = this.formatTimeUnit(timeObject.seconds);

    const today = moment(
      this.contactForm.value?.serviceStartDateInput+' '+timeObject+':00'
    )
      .format('DD/MM/YYYY HH:mm:ss.SSS');
    // const today = moment(this.contactForm.value?.serviceStartDateInput+' '+hours+':'+minutes+':'+seconds).format('YYYY-MM-DDTHH:mm:ss[Z]');
    const dateTimeString = `${today}`;
    return moment.utc(dateTimeString, 'DD/MM/YYYY HH:mm:ss.SSS').unix();
    // return today;
  }

  timeToNotUnixEditDuplicate(h: any) {
    var hours = h;
    var seconds = '00';

    const today = moment(
      this.contactForm.value?.serviceStartDateInput +
        ' ' +
        hours +
        ':' +
        seconds
    )
      .format('DD/MM/YYYY HH:mm:ss.SSS');
    // const today = moment(this.contactForm.value?.serviceStartDateInput+' '+hours+':'+minutes+':'+seconds).format('YYYY-MM-DDTHH:mm:ss[Z]');
    const dateTimeString = `${today}`;
    return moment.utc(dateTimeString, 'DD/MM/YYYY HH:mm:ss.SSS').unix();
    // return today;
  }

  onSingleTimeChange(value: string): void {
    this.singleTime = value;
    // console.log(value, "VALUE")
  }

  getProductIdByName(productName: any) {
    // console.log(productName, this.getVerticalValue(this.contactForm.get("vertical")?.value), this.productsOfVertical, 44);
    const product = this.productsOfVertical.find(
      (p: any) => p.productName === productName
    );
    return product ? product.productId : null;
  }

  getProductIdByTra(productName: any) {
    console.log(this.productsOfVertical);
    const product = this.productsOfVertical.find(
      (p: any) =>
        p.traduction === productName &&
        p.verticalType ==
          this.getVerticalValue(this.contactForm.get('vertical')?.value)
    );
    return product ? product.productId : null;
  }

  getProductRequestProvider(productName: any) {
    // console.log(this.productsOfVertical)
    const product = this.productsOfVertical.find(
      (p: any) => p.productName === productName
    );
    return product ? product.doesRequiresProvider : null;
  }

  getProductRequestProviderByid(productId: any) {
    // console.log(this.productsOfVertical)
    const product = this.productsOfVertical.find(
      (p: any) => p.productId === productId
    );
    return product ? product.doesRequiresProvider : null;
  }

  getProductIdById(productId: any) {
    const product = this.productsOfVertical.find(
      (p: any) => p.productId == productId
    );
    return product ? product : null;
  }
}
