<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left">Asignar conductor {{ filter == 'broom' ? 'coche escoba' : '' }}</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
   <div class="modal-body">
        <div id="modal_assign_driver_service"></div>
        <div id="modal_assign_driver_service_alert"></div>
        <form [formGroup]="assignForm" (ngSubmit)="assignDriver()" class="d-flex flex-column gap-4">
            <div class="form-group">
                <p>
                    Hay {{ drivers_count }} conductores disponibles para el servicio, Hoy a las {{ getDateHour(service_info.serviceDates.servicePickupDateRangeStart?.seconds) }}, {{ service_info.mainServiceTypeName }} - {{ service_info.vehicleData.licensePlate }}
                </p>
            </div>
            <div class="row col_options">
                <div class="col row_inside_options" [class.active_list_option]="option_view == 'closets'" (click)="changeOptionView('closets')">
                    Mas cercanos
                </div>
                <div class="col row_inside_options" [class.active_list_option]="option_view == 'all'" (click)="changeOptionView('all')">
                    Activos 
                </div>
                <!-- <div class="col row_inside_options" [class.active_list_option]="option_view == 'free'" (click)="changeOptionView('free')">
                    Mas tiempo libre
                </div> -->
            </div>
            <div class="row">
                <div class="col" [formGroup]="filterForm">
                    <tui-input formControlName="nameFilter" class="input_form" id="" tuiTextfieldIconLeft="tuiIconSearch" [tuiTextfieldLabelOutside]="true" (input)="filterData($event)">
                        Buscar
                    </tui-input>
                </div>
            </div>
            <div class="form-group">
                <div class="div_drivers_scroll" *ngIf="option_view == 'closets'">
                    <div *ngFor="let driver of drivers_for_service" (click)="selectDriver(driver)" [class.active_option_driver]="assignForm.value.driverId == driver.driverId">
                        <div class="row row_driver" *ngIf="service_info.driverInformation.id != driver.driverId">
                            <div class="col-12">
                                <p class="p_fs_15">
                                    <b>{{ driver.driverName }}</b> a {{ driver.distanceToService }} Km ({{ calculateJourneyTime(driver.distanceToService) }} aprox.)
                                </p>
                            </div>
                        </div>
                        <hr *ngIf="service_info.driverInformation.id != driver.driverId">
                    </div>
                </div>
                <div class="div_drivers_scroll" *ngIf="option_view == 'all'">
                    <div *ngFor="let driver of drivers_for_service2" (click)="selectDriver(driver)" [class.active_option_driver]="assignForm.value.driverId == driver.driverId">
                        <div class="row row_driver">
                            <div class="col-12">
                                <p class="p_fs_15">
                                    <b>{{ driver.general_info.fullName }}</b>
                                </p>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            Descartar
        </button>
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_footer"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!assignForm.valid" [attr.disabled]="!assignForm.valid ? true : null" *ngIf="!assignForm.valid">
                Asignar
        </button>
        <button tuiButton type="button" (click)="assignDriver()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m"  icon="tuiIconPlusCircleLarge" appearance="primary-button" *ngIf="assignForm.valid">
            Asignar
        </button>
        <div class="loader_inside_modal_button">
            <img src="/assets/logo-loading.gif" class="img_loader_inside_modal_button" alt="">
        </div>
    </div>
    <br>
</div>