import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaskitoOptions } from '@maskito/core';
@Component({
  selector: 'app-edit-client-price-modal',
  templateUrl: './edit-client-price-modal.component.html',
  styleUrls: ['./edit-client-price-modal.component.css'],
})
export class EditClientPriceModalComponent {
  @Input() bsModalRef!: any;
  @Input() zones: any;
  readonly maskOptions1: MaskitoOptions = {
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  };
  disponibility = new FormGroup({
    isVailable: new FormControl(true),
  });
  constructor() {}
}
