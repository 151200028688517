<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left">Editar nodo</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
   <div class="modal-body">
        <div id="modal_gestion_nodo"></div>
        <div id="modal_gestion_nodo_alert"></div>
        <form [formGroup]="assignForm" (ngSubmit)="assignProvider()" class="d-flex flex-column gap-4">
        	<p class="hid_p">
        		Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi, consequuntur impedit consequatur cumque commodi modi
        	</p>
        	<!-- <div class="form-group">
                <label class="label_form">Estado*</label>
                <select formControlName="nodeStatus" class="form-control input_form">
                    <option value="">Seleccione</option>
                    <option value="{{ st.enum }}" *ngFor="let st of statusList">
                    	{{ st.name }}
                    </option>
                </select>
            </div> -->
            <div class="form-group">
                <label class="label_form">Fecha*</label>
                <input type="date" formControlName="nodeDate" class="form-control" id="myDatePicker">
            </div>
            <div class="row">
                <div class="col">
                    <br>
                    <label class="label_form">Hora inicio</label>
                    <input type="text" class="form-control" id="timeInput" formControlName="nodeStartHour" placeholder="hh:mm" maxlength="5" (input)="onTimeInput('start')" />
                </div>
                <div class="col">
                    <br>
                    <label class="label_form">Hora fin</label>
                    <input type="text" class="form-control" id="timeInput" formControlName="nodeEndHour" placeholder="hh:mm" maxlength="5" (input)="onTimeInput('end')" />
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            Descartar
        </button>
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_footer"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!assignForm.valid" [attr.disabled]="!assignForm.valid ? true : null" *ngIf="!assignForm.valid">
                Editar
        </button>
        <button tuiButton type="button" (click)="assignProvider()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m"  icon="tuiIconPlusCircleLarge" appearance="primary-button" *ngIf="assignForm.valid">
            Editar
        </button>
        <div class="loader_inside_modal_button">
            <img src="/assets/logo-loading.gif" class="img_loader_inside_modal_button" alt="">
        </div>
    </div>
    <br>
</div>