<div class="page-header">
  <label class="page-header-title">{{
    "service_manager.pending_service.title" | translate
  }}</label>
  <div class="user-options">
    <tui-hosted-dropdown
      tuiDropdownLimitWidth="auto"
      [content]="sending_options"
      [(open)]="openSendingButton"
      size="m"
    >
      <button
        style="width: 200px"
        tuiButton
        type="button"
        [disabled]="!checked"
        size="m"
        appearance="secondary-button"
        tuiHostedDropdownHost
        class="sending-button"
      >
        <!-- {{ 'service_manager.pending_service.export_text' | translate }} (.csv, xls..) -->
        Enviar mensaje
      </button>
    </tui-hosted-dropdown>

    <button
      (click)="openModalDuplicateService()"
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3"
      size="m"
      appearance="primary-button"
      icon="tuiIconPlusCircleLarge"
    >
      {{ "service_manager.pending_service.button_new" | translate }}
    </button>
  </div>
</div>

<div class="table-body mt-5">
  <div class="table-body-head mb-3">
    <div>
      <label class="table-title">{{
        "service_manager.pending_service.title_list" | translate
      }}</label>
    </div>
    <tui-hosted-dropdown
      tuiDropdownLimitWidth="auto"
      [content]="download_options"
      [(open)]="open"
    >
      <label class="table-title-filter">Filter ({{ filterCount }})</label>

      <button tuiHostedDropdownHost class="export-button">
        <img
          class="img-icon"
          src="../../../assets/icons/download-Icon.svg"
          alt=""
        />
        {{ "service_manager.pending_service.export_text" | translate }} (.csv,
        xls..)
      </button>
    </tui-hosted-dropdown>
  </div>
  <div class="table-body-options mb-3">
    <div class="search-bar">
      <img
        src="../../../assets/icons/search- icon.svg"
        alt="Search Icon"
        class="search-icon"
      />
      <input
        #searchInput
        [disabled]="filterDisabled"
        id="searchInput"
        [(ngModel)]="searchTerm"
        (input)="funcionKeyUp(searchInput.value)"
        type="text"
        placeholder="{{
          'service_manager.pending_service.search_text' | translate
        }}"
        class="search-bar-input"
      />
    </div>
    <button
      [disabled]="filterDisabled || pendingServices.length < 1"
      (click)="filterDataBySelect()"
      appearance="primary-button"
      size="s"
      tuiButton
    >
      <tui-svg src="tuiIconSearch"></tui-svg>
      {{ "service_manager.pending_service.search_list_text" | translate }}
    </button>
    <tui-input-date
      [disabled]="filterDisabled"
      tuiTextfieldSize="m"
      [(ngModel)]="selectedDate"
      (ngModelChange)="filterDataByDate()"
      style="margin-top: -8px"
    >
      {{
        "service_manager.pending_service.date_input" | translate
      }}</tui-input-date
    >

    <!--        <select [disabled]="filterDisabled" (change)="filterDataByDate()"  class="form-select table-option-select" aria-label="status" [(ngModel)]="selectedZone" >
        <option   selected value="#">Zona</option>
        <option *ngFor="let zone of zones" [value]="zone.zoneId">{{ zone.zoneName }}</option>
      </select> -->

    <ng-select
      [items]="zones"
      bindLabel="zoneName"
      bindValue="zoneId"
      [multiple]="false"
      [(ngModel)]="selectedZone"
      [disabled]="filterDisabled"
      class="custom_select"
      (close)="filterDataByDate()"
      placeholder="{{
        'service_manager.pending_service.zone_input' | translate
      }}"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>

    <!--       <select [disabled]="filterDisabled"  class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedClient" (ngModelChange)="filterDataBySelect()">
        <option  selected value="#">Cliente</option>
        <option *ngFor="let client of clients" [value]="client">{{ client }}</option>
      </select> -->

    <ng-select
      [items]="all_clients"
      bindLabel="businessName"
      bindValue="userId"
      [multiple]="true"
      [(ngModel)]="selectedClient"
      [disabled]="filterDisabled"
      class="custom_select"
      (change)="filterDataBySelect()"
      placeholder="{{
        'service_manager.pending_service.clients_input' | translate
      }}"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>

    <ng-select
      [items]="products"
      bindLabel="product"
      [multiple]="true"
      [(ngModel)]="selectedProduct"
      [disabled]="filterDisabled"
      class="custom_select"
      (change)="filterDataBySelect()"
      placeholder="{{
        'service_manager.pending_service.products_input' | translate
      }}"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>
    <ng-select
      [items]="communication_states"
      bindLabel="name"
      bindValue="id"
      [multiple]="true"
      [(ngModel)]="selectedCommunication"
      [disabled]="filterDisabled"
      placeholder="Comunicación"
      (change)="filterDataBySelect()"
      [searchable]="false"
      [clearable]="false"
      class="custom-select"
      [dropdownPosition]="'bottom'"
    >
      <!-- Personalizar cada opción -->
      <ng-template ng-option-tmp let-item="item">
        <span
          style="border-top: 1px solid #e1e6ec; display: block; width: 100%"
          *ngIf="!item.color"
        ></span>
        <div class="option-item">
          <span
            [style.background]="item.color"
            style="width: 20px; height: 20px; border-radius: 7px"
            *ngIf="item.color"
          ></span>
          <span>{{ item.name }}</span>
          <span class="badge" style="margin-left: auto">{{ item.count }}</span>
        </div>
      </ng-template>
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div [style.background]="item.color">
          <span
            class="ng-value-icon left"
            (click)="clear(item)"
            *ngIf="item.disabled !== true"
            >×</span
          >
          <span>{{ item.name }}</span>
        </div>
        <!-- <img height="15" width="15" [src]="item.avatar" /> -->
      </ng-template>
    </ng-select>

    <!--        <select  [disabled]="filterDisabled"  class="form-select table-option-select" aria-label="role" [(ngModel)]="selectedProduct" (ngModelChange)="filterDataBySelect()">
        <option  selected value="#">Producto</option>
        <option *ngFor="let product of products" [value]="product">{{ product }}</option>
      </select> -->
  </div>
  <div>
    <table
      tuiTable
      size="l"
      style="width: 100%"
      [columns]="columns"
      (directionChange)="onSort($event)"
    >
      <thead>
        <tr tuiThGroup>
          <th class="th-style" *tuiHead="'selected'">
            <div class="horizontal-text-center">
              <input
                tuiCheckbox
                type="checkbox"
                [ngModel]="checked"
                (ngModelChange)="onCheck($event)"
              />
            </div>
          </th>
          <th
            class="th-style"
            *tuiHead="'licensePlate'"
            tuiTh
            (click)="setSortColumn('licensePlate')"
            [sticky]="true"
          >
            {{
              "service_manager.pending_service.plate_input_table" | translate
            }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'time'"
            tuiTh
            (click)="setSortColumn('startHour')"
          >
            {{ "service_manager.pending_service.hour_input_table" | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'order-hash'"
            tuiTh
            (click)="setSortColumn('orderHash')"
          >
            {{ "service_manager.pending_service.hash_input_table" | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'zone'"
            tuiTh
            (click)="setSortColumn('zoneName')"
          >
            {{ "service_manager.pending_service.zone_input_table" | translate }}
          </th>
          <th *tuiHead="'date'" tuiTh (click)="setSortColumn('formattedDate')">
            {{ "service_manager.pending_service.date_input_table" | translate }}
          </th>

          <th
            [sticky]="true"
            *tuiHead="'product'"
            tuiTh
            (click)="setSortColumn('mainProductName')"
          >
            {{
              "service_manager.pending_service.product_input_table" | translate
            }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'topping'"
            (click)="setSortColumn('toppingNames')"
            tuiTh
          >
            {{
              "service_manager.pending_service.toppings_input_table" | translate
            }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'client'"
            tuiTh
            (click)="setSortColumn('clientName')"
          >
            {{
              "service_manager.pending_service.client_input_table" | translate
            }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'communication'"
            tuiTh
            (click)="setSortColumn('communication')"
          >
            {{
              "service_manager.pending_service.communication_input_table"
                | translate
            }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'externalContactPhoneNumber'"
            tuiTh
            (click)="setSortColumn('externalContactPhoneNumber')"
          >
            {{
              "service_manager.pending_service.external_contact_input_table"
                | translate
            }}
          </th>
          <!-- <th [sticky]="true" *tuiHead="'priority'"  tuiTh    (click)="setSortColumn('isPriority')">Prioritario</th> -->
          <th
            [sticky]="true"
            *tuiHead="'pickupAddress'"
            tuiTh
            (click)="setSortColumn('servicePickupAddress')"
          >
            {{
              "service_manager.pending_service.pickup_address_input_table"
                | translate
            }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'deliveryAddress'"
            (click)="setSortColumn('serviceDeliveryAddress')"
            tuiTh
          >
            {{
              "service_manager.pending_service.delivery_input_table" | translate
            }}
          </th>
          <!-- <th [sticky]="true" *tuiHead="'provider'"  tuiTh>Proveedores</th> -->
          <th
            [sticky]="true"
            *tuiHead="'comment'"
            (click)="setSortColumn('comment')"
            tuiTh
          >
            {{
              "service_manager.pending_service.comments_input_table" | translate
            }}
          </th>
          <th *tuiHead="'actions'"></th>
        </tr>
      </thead>
      <tbody tuiTbody>
        <tr tuiTr *ngFor="let item of paginatedData">
          <td *tuiCell="'selected'" tuiTd>
            <div>
              <input
                tuiCheckbox
                type="checkbox"
                [size]="size"
                [hidden]="!item.isMobile"
                [(ngModel)]="item.selected"
              />
            </div>
          </td>
          <td *tuiCell="'licensePlate'" tuiTd>
            <div class="vertical-align">
              <span tuiTitle>
                {{ item.licensePlate }}
              </span>
              <span tuiSubtitle>
                {{ item.orderNumber }}
              </span>
            </div>
          </td>

          <td *tuiCell="'time'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.startHour + "-" + item.endHour }}
              </span>
            </div>
          </td>
          <td *tuiCell="'order-hash'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.orderHash }}
              </span>
            </div>
          </td>
          <td *tuiCell="'zone'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.zoneName }}
              </span>
            </div>
          </td>
          <td *tuiCell="'date'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.formattedDate }}
              </span>
            </div>
          </td>

          <td *tuiCell="'product'" tuiTd>
            <span tuiTitle>
              {{ hs.translate(item.mainProductName) }}
            </span>
          </td>
          <td *tuiCell="'topping'" tuiTd>
            <span tuiTitle>
              <label
                class="d-block"
                *ngFor="let topping of item.toppingNames"
                >{{ topping }}</label
              >
              <label *ngIf="item.toppingNames.length == 0">Sin Toppings </label>
            </span>
          </td>
          <td *tuiCell="'client'" tuiTd>
            <span tuiTitle>
              {{
                getClientById(item.clientId)
                  ? getClientById(item.clientId)
                  : item.clientName
              }}
            </span>
          </td>
          <!--              <td style="height: 105px;" class="d-flex justify-content-center align-items-center"  *tuiCell="'priority'" tuiTd>
                    
                        <tui-checkbox
                        [ngModel]="item.isPriority"
                            [readOnly]="true"></tui-checkbox>
                     
               </td> -->
          <td *tuiCell="'communication'" tuiTd>
            <div tuiCell="l" class="xxxx-cell">
              <span tuiTitle>
                <img
                  src="/assets/icons/communication-icons/answered.svg"
                  alt="whatsapp-icon"
                  *ngIf="item.whatsapp_answered"
                  [tuiHint]="'service_manager.pending_service.whatsapp_answered' | translate"
                  tuiHintDirection="bottom"
                  tuiHintPointer
                />
                <img
                  src="/assets/icons/communication-icons/waiting_answer.svg"
                  alt="whatsapp-icon"
                  *ngIf="item.whatsapp_waiting_answer"
                  [tuiHint]="'service_manager.pending_service.whatsapp_waiting_answer' | translate"
                  tuiHintDirection="bottom"
                  tuiHintPointer
                />
                <img
                  src="/assets/icons/communication-icons/error_sending.svg"
                  tuiLink
                  alt="whatsapp-icon"
                  *ngIf="item.whatsapp_error_sending"
                  [tuiHint]="'service_manager.pending_service.whatsapp_error_sending' | translate"
                  tuiHintDirection="bottom"
                  tuiHintPointer
                />
                <img
                  src="/assets/icons/communication-icons/error_sending_sms.svg"
                  tuiLink
                  alt="whatsapp-icon"
                  *ngIf="item.sms_error_sending || item.sms_error_handling"
                  [tuiHint]="'service_manager.pending_service.sms_error_sending' | translate"
                  tuiHintDirection="bottom"
                  tuiHintAppearance
                  tuiHintPointer
                />
                <img
                  src="/assets/icons/communication-icons/not_answered_in_time.svg"
                  tuiLink
                  alt="whatsapp-icon"
                  *ngIf="item.whatsapp_not_answered_in_time"
                  [tuiHint]="'service_manager.pending_service.whatsapp_not_answered_in_time' | translate"
                  tuiHintDirection="bottom"
                  tuiHintPointer
                />
                <img
                  src="/assets/icons/communication-icons/sms_sent.svg"
                  tuiLink
                  alt="whatsapp-icon"
                  *ngIf="item.sms_sent"
                  [tuiHint]="'service_manager.pending_service.sms_sent' | translate"
                  tuiHintDirection="bottom"
                  tuiHintPointer
                />
              </span>
            </div>
          </td>
          <td *tuiCell="'externalContactPhoneNumber'" tuiTd>
            <div tuiCell="l" class="external-contact-cell">
              <span
                tuiTitle
                [tuiHint]="item.contactPhoneNumberHint"
                tuiHintDirection="bottom"
                tuiHintPointer
              >
                {{ item.externalContactPhoneNumber }}
              </span>
            </div>
          </td>
          <td *tuiCell="'pickupAddress'" tuiTd>
            <div tuiCell="l" class="address-cell">
              <span tuiTitle>
                {{ item.servicePickupAddress }}
              </span>
            </div>
          </td>
          <td *tuiCell="'deliveryAddress'" tuiTd>
            <div tuiCell="l" class="address-cell">
              <span tuiTitle>
                {{ item.serviceDeliveryAddress }}
              </span>
            </div>
          </td>
          <!--             <td  *tuiCell="'provider'" tuiTd>
                <span tuiTitle>
                    {{item.provider}}
                  </span>
            </td> -->
          <td *tuiCell="'comment'" tuiTd>
            <span tuiTitle>
              <label>{{ item.comment }}</label>
              <label *ngIf="item.comment.length == 0">Sin Comentarios</label>
            </span>
          </td>
          <td *tuiCell="'actions'" tuiTd>
            <span tuiStatus>
              <button
                class="seeAllIcon"
                appearance="link"
                icon="tuiIconEye"
                size="xs"
                tuiAppearance="link"
                tuiIconButton
                (click)="viewDetail(item)"
              >
                <span>Ver resultados</span>
              </button>
              <button
                (click)="openModalEditService(item)"
                appearance="link"
                icon="tuiIconEdit2"
                size="xs"
                tuiAppearance="link"
                tuiIconButton
              >
                Edit
              </button>
              <!--                         <button
                            appearance="link"
                            icon="tuiIconMoreHorizontal"
                            size="xs"
                            tuiAppearance="link"
                            tuiIconButton
                        >
                            More
                        </button> -->
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-pagination mt-5">
    <tui-pagination
      [index]="index"
      [length]="length"
      (indexChange)="goToPage($event)"
    ></tui-pagination>
  </div>
</div>
<ng-template #download_options let-activeZone>
  <div class="download-menu">
    <label class="mb-2 export-title">{{
      "service_manager.pending_service.complement_export_text" | translate
    }}</label>

    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isXlsxSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.xlsx)</label>
    </div>

    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isCsvSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.csv)</label>
    </div>

    <button (click)="exportTo()" class="export-button-menu mb-2 mt-1">
      <img
        class="img-icon"
        src="../../../assets/icons/download-Icon.svg"
        alt=""
      />
      {{ "service_manager.pending_service.export_text" | translate }} ({{
        exportCount
      }})
    </button>
  </div>
</ng-template>

<ng-template #sending_options let-activeZone>
  <div class="sending-menu">
    <form [formGroup]="form" (ngSubmit)="sendTo()">
      <div orientation="vertical" tuiGroup class="group">
        <tui-radio-list
          formControlName="sendingMethod"
          size="l"
          [itemContent]="sendingMethodContent"
          [items]="sendingMethods"
        >
          <ng-template #sendingMethodContent tuiRadioList let-item>
            <div class="send-method">{{ item }}</div>
          </ng-template>
        </tui-radio-list>
      </div>
      <button
        type="submit"
        tuiButton
        [disabled]="
          this.form.value.sendingMethod === null ||
          this.form.value.sendingMethod === undefined ||
          this.form.value.sendingMethod === ''
        "
        size="m"
        appearance="primary-button"
        class="export-button-menu mb-2 mt-1"
      >
        Enviar
      </button>
    </form>
    <!-- <label class="mb-2 export-title">{{ 'service_manager.pending_service.complement_export_text' | translate }}</label> -->

    <!-- <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isXlsxSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.xlsx)</label>
    </div>

    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isCsvSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.csv)</label>
    </div> -->

    <!-- <img
        class="img-icon"
        src="../../../assets/icons/download-Icon.svg"
        alt=""
      /> -->
    <!-- {{ 'service_manager.pending_service.export_text' | translate }} ({{ exportCount }}) -->
    <!-- Enviar
    </button> -->
  </div>
</ng-template>

<ng-template #aa> </ng-template>
