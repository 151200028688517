<div class="table-body">
	<div class="row">
		<div class="col-2">
			<ng-select
	            [multiple]="false"
	            [items]="zones"
	            bindLabel="zoneName"
	            bindValue="zoneId"
	            class="custom_select custom_select_single input_form"
	            placeholder="Zona operativa"
	            [(ngModel)]="activeZone"
	        >
	        </ng-select>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<br>
			<p class="p_17">
				<img src="/assets/img/tarifas.png" width="19"> Tarifas
			</p>
		</div>	
		<div class="col-6">
	     	<table tuiTable size="l" style="width: 100%;" [columns]="columns_tarifas">
			    <thead>
			        <tr tuiThGroup>
			            <th class="th-style" *tuiHead="'fees'" tuiTh [sticky]="true">Fees</th>
			            <th [sticky]="true" *tuiHead="'price'" tuiTh>Precio Tarifa</th>
			            <th [sticky]="true" *tuiHead="'actions'" tuiTh>Acciones</th>
			        </tr>
			    </thead>
			    <tbody tuiTbody>
			    	<tr tuiTr>
			    		<td *tuiCell="'fees'" tuiTd>
			    			Hora Fija
			    		</td>
			    		<td *tuiCell="'price'" tuiTd>
			    			22.5
			    		</td>
			    		<td *tuiCell="'actions'" tuiTd>
			    			<tui-icon icon="tuiIconEdit2" class="pointer_item" (click)="openModal(editClientPriceModal, 'fix', '22.5')"></tui-icon>
			    		</td>
			    	</tr>
			    	<tr tuiTr>
			    		<td *tuiCell="'fees'" tuiTd>
			    			Fee Urgencia
			    		</td>
			    		<td *tuiCell="'price'" tuiTd>
			    			22.7
			    		</td>
			    		<td *tuiCell="'actions'" tuiTd>
			    			<tui-icon icon="tuiIconEdit2" class="pointer_item" (click)="openModal(editClientPriceModal, 'urg', '22.7')"></tui-icon>
			    		</td>
			    	</tr>
			    </tbody>
	 		</table>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<br><br>
			<p class="p_17">
				<img src="/assets/img/productos.png" width="26"> Productos
			</p>
			<hr>
		</div>	
	</div>
	<div class="row" *ngFor="let item of productsInfo; let i = index">
		<div class="col-12">
			<br>
			<p class="p_17">
				{{ getVerticalRealName(item.product) }} ({{ item.productData.length }})<tui-icon icon="tuiIconChevronRight" class="pointer_item" (click)="setOpenedDiv(item.product)" *ngIf="openedDiv != item.product"></tui-icon>
				<tui-icon icon="tuiIconChevronDown" class="pointer_item" (click)="closeOpenedDiv(item.product)" *ngIf="openedDiv == item.product"></tui-icon>
			</p>
		</div>
		<div class="col-12" *ngIf="openedDiv == item.product">
		    <div class="overflow-auto">
		     	<table tuiTable size="l" style="width: 100%;text-align: center;" [columns]="columns">
				    <thead>
				        <tr tuiThGroup>
				            <th class="th-style" *tuiHead="'vertical'" tuiTh [sticky]="true">
				            	Productos
				            </th>
				            <th [sticky]="true" *tuiHead="'movi_b2c'" tuiTh>Precio B2C Movilidad</th>
				            <th [sticky]="true" *tuiHead="'prov_b2c'" tuiTh>Precio B2C Proveedor</th>
				            <th [sticky]="true" *tuiHead="'movi_b2b'" tuiTh>Precio B2B Movilidad</th>
				            <th [sticky]="true" *tuiHead="'prov_b2b'"  tuiTh>Precio B2B Proveedor</th>
				            <th [sticky]="true" *tuiHead="'movi_pre'"  tuiTh>Precio Premium Movilidad</th>
				            <th [sticky]="true" *tuiHead="'prov_pre'"  tuiTh>Precio Premium Proveedor</th>
				            <th [sticky]="true" *tuiHead="'movi_hyb'"  tuiTh>Precios Hybrid Movilidad</th>
				            <th [sticky]="true" *tuiHead="'prov_hyb'"  tuiTh>Precios Hybrid Proveedor</th>
				            <th [sticky]="true" *tuiHead="'actions'"  tuiTh>Acciones</th>
				        </tr>
				    </thead>
				    <tbody tuiTbody>
				        <tr tuiTr *ngFor="let pr of item.productData">
				            <td *tuiCell="'vertical'" tuiTd>
				                <div [tuiCell]="size">
				                    <span tuiTitle>
				                   		{{ pr.subProduct }}
				                    </span>
				                </div>
				            </td>
				            <td  *tuiCell="'movi_b2c'" tuiTd>
				                <div [tuiCell]="size">
				                    <span tuiTitle>
				                        {{ pr.p1 }}
				                    </span>
				                </div>
				            </td>
				            <td  *tuiCell="'prov_b2c'" tuiTd>
				                <div [tuiCell]="size">
				                    <span tuiTitle>
				                        {{ pr.p2 }}
				                    </span>
				                </div>
				            </td>
				            <td  *tuiCell="'movi_b2b'" tuiTd>
				                <span tuiTitle>
				                    {{ pr.p3 }}
				                 </span>
				            </td>
				            <td  *tuiCell="'prov_b2b'" tuiTd>
				                <div [tuiCell]="size">
				                    <span tuiTitle>
				                       {{ pr.p4 }}
				                    </span>
				                </div>
				            </td>
				            <td  *tuiCell="'movi_pre'" tuiTd>
				                <div [tuiCell]="size">
				                    <span tuiTitle>
				                       {{ pr.p5 }}
				                    </span>
				                </div>
				            </td>
				            <td  *tuiCell="'prov_pre'" tuiTd>
				                <div [tuiCell]="size">
				                    <span tuiTitle>
				                       {{ pr.p6 }}
				                    </span>
				                </div>
				            </td>
				            <td  *tuiCell="'movi_hyb'" tuiTd>
				            	<div [tuiCell]="size">
					                <span tuiTitle>
					                    {{ pr.p7 }}
					                </span>
					            </div>
				            </td>
				            <td  *tuiCell="'prov_hyb'" tuiTd>
				            	<div [tuiCell]="size">
					                <span tuiTitle>
					                    {{ pr.p8 }}
					                </span>
					            </div>
				            </td>
				            <td  *tuiCell="'actions'" tuiTd>
				                <span tuiTitle>
				                	<tui-icon icon="tuiIconEdit2" class="pointer_item" (click)="openModalProduct(editProductModal, pr)"></tui-icon>
				                </span>
				            </td>
				        </tr>
				    </tbody>
		 		</table>
			</div>  
		</div>
		<div class="col-12">
			<hr>
		</div>
	</div>
	<ng-template #editClientPriceModal>
		<div class="modal-body">
	        <div class="content_cancel_service">
	            <br>
	            <p class="fs_24">
	                Editar {{ activeEditPrice }}
	            </p>
	        </div>
	        <div class="form-group">
	            <label>Preco tarifa</label>
	            <input type="text" class="form-control" [(ngModel)]="priceToEdit">
	        </div>
	        <div class="right_buttons">
	            <br>
	            <button class="close_modal_logout" (click)="bsModalRef?.hide()">
	            	Salir sin guardar
	            </button>
	            <button class="add_topping_but" (click)="saveModal()">
	               Guardar
	            </button>
	            <br>
	            <br>
	            <div class="div_loader_modal div_inside_edit_assigned_hour">
	                <img src="assets/logo-loading.gif" class="img_loader_inside_modal" alt="">
	            </div>
	        </div>
	    </div>
	</ng-template>
	<ng-template #editProductModal>
		<div class="modal-body">
	        <div class="content_cancel_service">
	            <br>
	            <p class="fs_24">
	                Editar {{ activeEditProduct }}
	            </p>
	        </div>
	        <div class="row">
	        	<div class="col-12">
	        		<p class="edit_section_title">
	        			Precios B2C
	        		</p>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio B2C Movilidad</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEditb2c1">
			        </div>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio B2C Proveedor</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEditb2c2">
			        </div>
	        	</div>
	        	<div class="col-12">
	        		<hr>
	        	</div>
	        	<div class="col-12">
	        		<p class="edit_section_title">
	        			Precios B2B
	        		</p>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio B2B Movilidad</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEditb2b1">
			        </div>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio B2B Proveedor</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEditb2b2">
			        </div>
	        	</div>
	        	<div class="col-12">
	        		<hr>
	        	</div>
	        	<div class="col-12">
	        		<p class="edit_section_title">
	        			Precios Premium
	        		</p>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio Premium Movilidad</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEditpre1">
			        </div>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio Premium Proveedor</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEditpre2">
			        </div>
	        	</div>
	        	<div class="col-12">
	        		<hr>
	        	</div>
	        	<div class="col-12">
	        		<p class="edit_section_title">
	        			Precios Hybrid
	        		</p>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio Hybrid Movilidad</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEdithyb1">
			        </div>
	        	</div>
	        	<div class="col-6">
			        <div class="form-group">
			            <label>Precio Hybrid Proveedor</label>
			            <input type="text" class="form-control" [(ngModel)]="priceToEdithyb2">
			        </div>
	        	</div>
	        	<div class="col-12">
	        		<hr>
	        	</div>
	        </div>
	        <div class="right_buttons">
	            <br>
	            <button class="close_modal_logout" (click)="bsModalRef?.hide()">
	            	Salir sin guardar
	            </button>
	            <button class="add_topping_but" (click)="saveModal()">
	               Guardar
	            </button>
	            <br>
	            <br>
	            <div class="div_loader_modal div_inside_edit_assigned_hour">
	                <img src="assets/logo-loading.gif" class="img_loader_inside_modal" alt="">
	            </div>
	        </div>
	    </div>
	</ng-template>
</div>
