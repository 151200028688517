import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, ViewChild, TemplateRef, Input, NgZone, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, NgControl } from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { TuiDay  } from '@taiga-ui/cdk';
declare var  moment: any
declare var google: any;
import { MapaServiceService } from 'src/app/services/mapa/mapa-service.service';

@Component({
  selector: 'app-modal-assign-driver',
  templateUrl: './modal-assign-driver.component.html',
  styleUrls: ['./modal-assign-driver.component.css']
})
export class ModalAssignDriverComponent {
    @ViewChild('originAddress', { static: true }) originAddressInput!: ElementRef;
    @ViewChild('destinationAddress', { static: true }) destinationAddressInput!: ElementRef;
    @Input() order_hash: any;
    @Input() service_info: any;
    @Input() filter: any;
    @Input() nodeInfo: any;
    active_driver:any;
    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private fb: FormBuilder,
        private offcanvasService: NgbOffcanvas,
        private hs:HistoryServicesService,
        private ngZone: NgZone,
        private ms: MapaServiceService,
        private renderer: Renderer2
    ) {}

    drivers_count = 0;
    drivers_count2 = 0;
    option_view = 'closets';
    assignForm = this.fb.group({
        orderHash:['', Validators.required],
        driverId:['', Validators.required]
    });

    drivers_for_service:any = [];
    drivers_for_service2:any = [];
    elements_drivers:any = [];
    elements_drivers2:any = [];
    filterForm = this.fb.group({
        nameFilter:''
    });
    ngOnInit(){
        this.assignForm.patchValue({
            orderHash:this.order_hash
        });
        let data = {
            orderHash:this.order_hash,
            filterType:'PROXIMITY'
        }
        this.showLoader();
        this.ms.getMapDrivers(data).subscribe((data:any)=>{
            data.forEach((obj:any, index:any) => {
                obj.driversForService.forEach((obj2:any, index2:any) => {
                    if(!this.drivers_for_service.find((driver: any) => driver.driverId === obj2.driverId)){
                        this.elements_drivers2.push(obj2);
                        // this.drivers_for_service.push(obj2);
                    }
                });
            });
            // this.drivers_for_service.sort((a:any, b:any) => a.distanceToService - b.distanceToService);
            // this.drivers_count = this.drivers_for_service?.length;
            // this.hideLoader();

            // let dat = {
            //     driverName:'Peter perez'
            // }

            // this.elements_drivers2.push(dat);

            this.drivers_for_service = [...this.elements_drivers2];
            this.drivers_for_service.sort((a:any, b:any) => a.distanceToService - b.distanceToService);
            this.drivers_count = this.drivers_for_service?.length;
        });

        const serviceData = {
            date: {seconds: this.service_info.serviceDates.servicePickupDateRangeStart.seconds},
            zoneId: this.service_info.zoneId 
        };

        this.ms.getServices(serviceData).subscribe((services:any)=>{
            console.log(services);
            services.forEach((obj:any, index:any) => {
                obj.activeDrivers.forEach((obj2:any, index2:any) => {
                    if(!this.elements_drivers.find((driver: any) => driver.driverId === obj2.driverId)){
                        this.elements_drivers.push(obj2);
                    }
                });
            });

            let driverPromises = this.elements_drivers.map((obj: any, index: any) => {
                return this.ms.getDriver(obj.driverId).toPromise().then((data: any) => {
                    let general_info = data.drivers[0]!;
                    obj.general_info = general_info;
                });
            });

            Promise.all(driverPromises).then(() => {
                if(this.filter == 'broom'){
                    this.drivers_for_service2 = [...this.elements_drivers.filter((x: any) => 
                        x.general_info !== undefined && x.general_info.isActive === true && x.general_info.operatorType == 'BROOM_CAR'
                    )];
                    this.drivers_count2 = this.elements_drivers.filter((x: any) => 
                        x.general_info !== undefined && x.general_info.isActive === true && x.general_info.operatorType == 'BROOM_CAR'
                    )?.length;
                }else{
                    this.drivers_for_service2 = [...this.elements_drivers.filter((x: any) => 
                        x.general_info !== undefined && x.general_info.isActive === true
                    )];
                    this.drivers_count2 = this.elements_drivers.filter((x: any) => 
                        x.general_info !== undefined && x.general_info.isActive === true
                    )?.length;
                }
                this.hideLoader();

                console.log(this.drivers_for_service2);
            });
        });
    }

    filterData(event: Event): void {
        const query = (event.target as HTMLInputElement).value.toLowerCase();
        if(this.option_view == 'closets'){
            this.drivers_for_service = this.elements_drivers2.filter((item:any) =>
                item.driverName.toLowerCase().includes(query)
            );
        }
        if(this.option_view == 'all'){
            console.log(this.elements_drivers);
            this.drivers_for_service2 = this.elements_drivers.filter((item:any) =>
                item.general_info !== undefined && item.general_info.isActive === true 
                && item.general_info?.fullName.toLowerCase().includes(query)
            );
        }
    }

    closeCanvas(){
        this.offcanvasService.dismiss();
    }

    div_loder = document.getElementById('loader');
    showLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'flex');
        }
    }

    hideLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'none');
        }

        setTimeout(()=>{
            const closeDiv = document.querySelector('.t-close');
            if (closeDiv) {
              (closeDiv as HTMLElement).click();
            }
        },5000);
    }

    showLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'flex'; // Agrega display: flex al div
        }
    }

    hideLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'none'; // Agrega display: flex al div
        }
    }

    showErrorMsj(){
        setTimeout(()=>{
            const sourceElement = document.querySelector('tui-alert');
            const targetElement = document.getElementById('modal_assign_driver_service_alert');
            if (sourceElement && targetElement) {
                // console.log('replicar');
                const clonedNode = sourceElement.cloneNode(true);
                // targetElement!.innerHTML = '';
                targetElement.appendChild(sourceElement);
            }
        },300);
    }

    assignDriver(){
        this.showLoaderModal();
        // this.ms.assignDriver(this.assignForm.value).subscribe((data:any)=>{
        //     this.hs.publish('updateDetailsService');
        //     this.hideLoaderModal();
        //     this.offcanvasService.dismiss();
        //     this.showDepositAlert(
        //         'El servicio de se ha asignado a '+this.active_driver.driverName,
        //         'Se ha asignado el servicio de forma satisfactoria'
        //     );
        // });

        let data = {
            driverId: this.assignForm.value.driverId,
            isSweeperAssignation: this.filter == 'all' ? false : true,
            nodeId:this.nodeInfo.nodeId,
            orderHash:this.order_hash
        }

        // console.log(data);

        this.ms.assignDriver(data).subscribe((res: any) => {
            if (!res.wasSuccessful && res?.statusCode) {
                this.hs.showErrorCode(res.statusCode);
                this.showErrorMsj();
            } else {
                this.hs.publish('updateDetailsService');
                this.offcanvasService.dismiss();
                // this.showDepositAlert(
                //     'El servicio de se ha asignado a '+this.active_driver.driverName,
                //     'Se ha asignado el servicio de forma satisfactoria'
                // );
                let nameDriver = this.active_driver.driverName ? this.active_driver.driverName : this.active_driver.general_info.fullName;
                if(this.filter == 'all'){
                    this.hs.publish('updateDetailsService');
                    this.offcanvasService.dismiss();
                    this.showDepositAlert(
                        'El nodo de se ha asignado a '+nameDriver,
                        'Se ha asignado el conductor de forma satisfactoria'
                    );
                }else{
                    this.hs.publish('updateDetailsService');
                    this.offcanvasService.dismiss();
                    this.showDepositAlert(
                        'El nodo de se ha asignado a '+nameDriver,
                        'Se ha asignado el conductor coche escoba de forma satisfactoria'
                    );
                }
            }
            this.hideLoaderModal();
        },
        (error) => {
          this.hideLoaderModal();
          if (error.status === 500) {
            this.alerts
              .open('Problema en el servidor', {
                label: 'Error 500',
                status: 'error',
                autoClose: false,
              })
              .subscribe();
            this.showErrorMsj();
          } else {
            this.alerts
              .open(error, {
                label: 'Otro error',
                status: 'error',
                autoClose: false,
              })
              .subscribe();
            this.showErrorMsj();
          }
        });

        // console.log(this.filter);
        
    }

    showDepositAlert(title:any,msj:any): void {
        this.alerts.open(msj, {
            label: title,
            status: 'success',
            autoClose: true,
        }).subscribe();
    }

    selectDriver(driver:any){
        this.assignForm.patchValue({
            driverId:driver.driverId
        });

        this.active_driver = driver;
    }

    changeOptionView(item:any){
        this.option_view = item;
    }

    calculateJourneyTime(distance:any) {
        let speed = 70;
        let journeyTime = '';
        if (distance > 0 && speed > 0) {
            const timeInHours = distance / speed;
            const hours = Math.floor(timeInHours);
            const minutes = Math.round((timeInHours - hours) * 60);

            journeyTime = `${hours}h ${minutes}m`;
        } else {
          journeyTime = 'La distancia y la velocidad deben ser mayores que 0';
        }

        return journeyTime;
    }

    formatTimeUnit(unit:any) {
        return unit < 10 ? `0${unit}` : unit;
    }

    getDateHour(seconds:any){
        const date = new Date(seconds * 1000); 

        const hour = this.formatTimeUnit(date.getHours());
        const min = this.formatTimeUnit(date.getMinutes());

        return `${hour}:${min}`;
    }
}
