import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TuiAlertModule, TuiDialogModule, TuiRootModule,TUI_ALERT_POSITION} from '@taiga-ui/core';
import { TuiTagModule } from '@taiga-ui/kit';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';

import { MsalModule, MSAL_INSTANCE, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, MsalGuard } from "@azure/msal-angular";
import { PublicClientApplication, IPublicClientApplication, BrowserCacheLocation, InteractionType } from "@azure/msal-browser";
import { ClientsTableComponent } from './shares/clients-table/clients-table.component';
import { HomeComponent } from './pages/home/home.component';
import { HomeModule } from './pages/home/home.module';
import { PlatformBlockerComponent } from './pages/platform-blocker/platform-blocker.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateEnumPipe } from './pipes/translate-enum.pipe';
import { PricesComponent } from './pages/prices/prices.component';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {

    return new PublicClientApplication({
        auth: {
            clientId: "1649588b-cd93-42f9-bbb4-d8db8ce706d8", // ID de aplicación (cliente) registrado
            authority: "https://login.microsoftonline.com/organizations", // Instancia de Azure y audiencia de inicio de sesión de la aplicación (tenant ID, common, organizations o consumers)
            redirectUri: "/", // Esta es tu URI de redireccionamiento
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        },
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['api://cafler-logistics-api/Test.TestRead'],
    },
    loginFailedRoute: '/login-failed'
  };
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule, 
    TuiRootModule, 
    TuiTagModule,
    TuiAlertModule,
    TuiDialogModule,
    SharedModule,
    AppRoutingModule,
    HomeModule,
    HttpClientModule, 
    MsalModule,
    TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
  ],
  providers: [
        MsalGuard,
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        {
          provide: TUI_ALERT_POSITION,
          useValue: 'auto auto 2rem 3rem'  
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        MsalService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
