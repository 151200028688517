<!-- Modal -->
<div id="myModal" class="modal-content">
  <div class="modal-header-custom d-flex justify-content-between">
    <div>
      <div class="service-data">
        <span
          *ngIf="serviceData.nodeType == 'OPERATIVE_NODE'"
          class="service-name"
          >{{ getVertical(serviceData.verticalType) }}</span
        >
        <span
          *ngIf="serviceData.nodeType == 'SWEEPER_NODE'"
          class="service-name"
        >
          Coche Escoba
        </span>
        <span
          *ngIf="serviceData.nodeType == 'TRANSPORT_NODE'"
          class="service-name"
        >
          Transporte público
        </span>
        <div
          class="display-contents"
          *ngIf="serviceData.nodeType != 'TRANSPORT_NODE'"
        >
          <span class="service-hash"
            >Order hash {{ serviceData.orderHash }}</span
          >
          <span class="service-product">{{
            serviceData.licensePlate + "-" + serviceData.vehicleChassis
          }}</span>
        </div>
      </div>
      <!--     <tui-badge
      size="m"
    status="info"
    value="Picking-up"
    class="tui-space_right-2 tui-space_bottom-2 mt-2"
></tui-badge> -->

      <div *ngIf="serviceData?.orderStatus == 'INITIALIZED'">
        <tui-badge
          status="neutral"
          value="Inicializado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_initalized"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'PENDING'">
        <tui-badge
          status="neutral"
          value="Pendiente"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_pending"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'CONFIRMED'">
        <tui-badge
          status="success"
          value="Confirmado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_confirmed"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'PICKING_UP'">
        <tui-badge
          status="success"
          value="Recogiendo"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'IN_PROGRESS'">
        <tui-badge
          status="success"
          value="En proceso"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'DELIVERING'">
        <tui-badge
          status="success"
          value="Entregando"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'FINISHED'">
        <tui-badge
          status="success"
          value="Finalizado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_finished"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'FAILED'">
        <tui-badge
          status="error"
          value="Fallado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'CANCELLED_BY_CAFLER'">
        <tui-badge
          status="error"
          value="Cancelado por Cafler"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'CANCELLED_BY_CLIENT'">
        <tui-badge
          status="error"
          value="Cancelado por cliente"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
    </div>
    <div>
      <!--       <tui-badge
      size="m"
      status="custom"
      value="Prioritario"
      class="tui-space_right-2 support-11 "
  >
      <tui-svg style="color: #806609 !important;" src="tuiIconStar"></tui-svg>
  </tui-badge> -->
    </div>
    <button
      type="button"
      class="btn-close"
      (click)="bsModalRef?.hide()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body-custom mt-3">
    <div
      *ngIf="serviceData.nodeType != 'TRANSPORT_NODE'"
      class="client-data d-flex align-items-center mb-1"
    >
      <tui-svg src="tuiIconUser"></tui-svg>
      <div *ngIf="serviceData.nodeType == 'OPERATIVE_NODE'">
        <span class="fw-bold">Cliente:</span>
        <span>{{ serviceData.clientName }} </span>
      </div>
      <div *ngIf="serviceData.nodeType == 'SWEEPER_NODE'">
        <span class="fw-bold">Conductor:</span>
        <span>{{ serviceData.driverName }} </span>
      </div>
    </div>
    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconClock"></tui-svg>
      <div>
        <span class="fw-bold">Hora de Recogida:</span>
        <span> {{ formatTime(serviceData.serviceStartDate.seconds) }} </span>
      </div>
    </div>
    <div
      *ngIf="
        serviceData.nodeType == 'SWEEPER_NODE' ||
        serviceData.nodeType == 'TRANSPORT_NODE'
      "
      class="client-data d-flex align-items-center mb-1"
    >
      <tui-svg src="tuiIconClock"></tui-svg>
      <div>
        <span class="fw-bold">Hora de estimada de llegada:</span>
        <span> {{ formatTime(serviceData.serviceEndDate.seconds) }} </span>
      </div>
    </div>
    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconMapPin"></tui-svg>
      <div>
        <span class="fw-bold">Punto de Recogida:&nbsp;</span>
        <span> {{ serviceData.serviceStartAddress }} </span>
      </div>
    </div>

    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconMapPin"></tui-svg>
      <div>
        <span class="fw-bold">Punto de Entrega:&nbsp;</span>
        <span> {{ serviceData.serviceFinishAddress }} </span>
      </div>
    </div>
  </div>
  <div #littleGmapContainer id="map" class="map_modal mt-1"></div>
  <div
    *ngIf="serviceData.nodeType != 'TRANSPORT_NODE'"
    class="modal-footer-custom"
  >
    <button
      (click)="viewDetail(serviceData)"
      class="export-button-menu mb-2 mt-3"
    >
      Ver detalles del servicio
    </button>
  </div>
</div>
