<div class="modal-body">
    <div class="content_cancel_service">
        <br>
        <p class="fs_24">
            {{ 'detail_service.add_event_modal_title' | translate }}
        </p>
    </div>
    <div class="form-group">
        <label>{{ 'detail_service.add_event_title' | translate }}</label>
        <input type="text" class="form-control">
    </div>
    <div class="form-group">
        <label>{{ 'detail_service.add_event_description' | translate }}</label>
        <textarea class="form_textarea2" rows="5"></textarea>
    </div>
    <div class="right_buttons">
        <br>
        <!-- <button class="close_modal_logout" (click)="modalRef?.hide()">
            {{ 'detail_service.back' | translate }}
        </button> -->
        <button class="add_topping_but">
           {{ 'detail_service.add' | translate }}
        </button>
        <br>
        <br>
        <div class="div_loader_modal div_inside_add_event">
            <img src="assets/logo-loading.gif" class="img_loader_inside_modal" alt="">
        </div>
    </div>
</div>