<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="type_info == 'du'">{{ 'modals.add_service.duplicate_title_text' | translate }}</h4>
        <h4 class="modal-title pull-left" *ngIf="type_info == 'ed'">{{ 'modals.add_service.edit_title_text' | translate }}</h4>
        <h4 class="modal-title pull-left" *ngIf="type_info == 'ad'">{{ 'modals.add_service.title' | translate }}</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
   <div class="modal-body">
        <div id="modal_duplicate_service"></div>
        <div id="modal_duplicate_service_alert"></div>
        <form [formGroup]="contactForm" (ngSubmit)="saveContactInfo()" class="d-flex flex-column gap-4">
            <div class="row">
                <div class="form-group info_header" *ngIf="type_info == 'du'">
                    <div class="row"> 
                        <div class="col-1">
                            <tui-icon icon="tuiIconInfoLarge" class="info_header_icon"></tui-icon>
                        </div>
                        <div class="col-11">
                            <p>
                                {{ 'modals.add_service.duplicate_title_complement' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <p class="p_label">
                        {{ 'modals.add_service.client_input' | translate }}*
                    </p>
                    <tui-combo-box
                        formControlName="clientName"
                        class="input_form"
                        [stringify]="stringifyNameclient"
                        [tuiTextfieldLabelOutside]="true"
                        (click)="onSelectClick($event)"
                        (focusedChange)="onSelectBlur($event)"
                        nativeId="select_cn"
                        tuiTextfieldSize="s"
                        [tuiTextfieldCleaner]="true"
                    >
                        {{ 'modals.add_service.client_input' | translate }}
                        <tui-data-list-wrapper
                            *tuiDataList
                            [itemContent]="stringifyNameclient | tuiStringifyContent"
                            [items]="clientRealList | tuiFilterByInputWith: stringifyNameclient"
                        ></tui-data-list-wrapper>
                    </tui-combo-box>
                    <hr class="hr_divider">
                </div>
                <div>
                    <p class="p_label">
                        Día y hora del servicio
                    </p>
                	<div class="form-group form-group-select2">
                        <label class="label_form">{{ 'modals.add_service.date_input' | translate }}*</label>
                        <input type="date" formControlName="serviceStartDateInput" class="form-control disabled_date" id="myDatePicker" readonly *ngIf="type_info == 'ed'">
                        <input type="date" formControlName="serviceStartDateInput" class="form-control" id="myDatePicker" *ngIf="type_info != 'ed'">
                    </div>
                    <div class="form-group form-group-select2">
                        <label class="label_form">{{ 'modals.add_service.type_hour_input' | translate }}</label>
                        <select formControlName="singleTime" class="form-control input_form">
                            <option value="">{{ 'modals.add_service.type_hour_input' | translate }}</option>
                            <option value="Franja horaria">{{ 'modals.add_service.franje_hour_input' | translate }}</option>
                            <option value="Hora fija">{{ 'modals.add_service.fixed_hour_input' | translate }}</option>
                        </select>
                    </div>
                    <div *ngIf="contactForm?.get('singleTime')?.value === 'Franja horaria'" class="row form-group-select">
                        <div class="col">
                            <label class="label_form">{{ 'modals.add_service.from_hour_input' | translate }}</label>
                            <input type="text" class="form-control" id="timeInput" formControlName="workdayStartTime" placeholder="hh:mm" maxlength="5" (input)="onTimeInput('start')" />
                        </div>
                        <div class="col">
                            <label class="label_form">{{ 'modals.add_service.to_hour_input' | translate }}</label>
                            <input type="text" class="form-control" id="timeInput" formControlName="workdayEndTime" placeholder="hh:mm" maxlength="5" (input)="onTimeInput('end')" />
                        </div>
                    </div>
                    <div *ngIf="contactForm?.get('singleTime')?.value === 'Hora fija'" class="form-group form-group-select">
                        <label class="label_form">{{ 'modals.add_service.fixed_hour_input' | translate }}*</label>
                        <input type="text" class="form-control" id="timeInput" formControlName="serviceStartHour" placeholder="hh:mm" maxlength="5" (input)="onTimeInput('hour')" />
                    </div>
                    <hr class="hr_divider">
                </div>
                <div>
                    <p class="p_label">
                        {{ 'modals.add_service.car_info_section' | translate }}
                    </p>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.type_car_input' | translate }}</label>
                        <select formControlName="VehicleTypeName" class="form-control input_form">
                            <option value="">{{ 'modals.add_service.type_car_input' | translate }}</option>
                            <option value="{{ ty.real_name }}" *ngFor="let ty of VehicleChassisType">
                                {{ ('enums.vehicles.'+ty.name.toLowerCase()) | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.brand_input' | translate }}</label>
                        <tui-input formControlName="VehicleBrand" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.brand_input' | translate }}
                        </tui-input>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.model_input' | translate }}</label>
                        <tui-input formControlName="VehicleModel" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.model_input' | translate }}
                        </tui-input>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.plate_input' | translate }}</label>
                        <tui-input formControlName="licensePlate" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.plate_input' | translate }}
                        </tui-input>
                    </div>
                    <div class="form-group form-group-select">
                        <label class="label_form">{{ 'modals.add_service.fuel_input' | translate }}</label>
                        <select formControlName="VehicleFuelName" class="form-control input_form">
                            <option value="">{{ 'modals.add_service.fuel_input' | translate }}</option>
                            <option value="{{ fu.real_name }}" *ngFor="let fu of VehicleFuelType">
                                {{ ('enums.fuels.'+fu.name.toLowerCase()) | translate }}
                            </option>
                        </select>
                    </div>
                    <hr class="hr_divider">
                </div>
                <div>
                    <p class="p_label">
                        Direcciones
                    </p>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.pickup_address_input' | translate }}*</label>
                        <input #originAddress  formControlName="originAddress" placeholder="{{ 'modals.add_service.pickup_address_input_inside' | translate }}"  type="text" class="form-control input_form">
                        <input type="hidden" formControlName="originAddressGeopointLt">
                        <input type="hidden" formControlName="originAddressGeopointLn"> 
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.pickup_address_info_input' | translate }}</label>
                        <tui-input formControlName="originAddressDetails" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.pickup_address_info_input_inside' | translate }}
                            <input
                                tuiTextfield
                                type="text"
                            />
                        </tui-input>
                    </div>
                    <div class="form-group">
                        <div class="col check_div">
                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="sameOriginAddress" size="m"></tui-checkbox> La dirección de entrega es la misma que de recogida
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.delivery_address_input' | translate }}*</label>
                        <input #destinationAddress  formControlName="destinationAddress" placeholder="{{ 'modals.add_service.delivery_address_input_inside' | translate }}"  type="text" class="form-control input_form">
                        <input type="hidden" formControlName="destinationAddressGeopointLt">
                        <input type="hidden" formControlName="destinationAddressGeopointLn"> 
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.delivery_address_info_input' | translate }}</label>
                        <tui-input formControlName="destinationAddressDetails" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.delivery_address_info_input_inside' | translate }}
                            <input
                                tuiTextfield
                                type="text"
                            />
                        </tui-input>
                    </div>
                    <div class="form-group form-group-select" *ngIf="type_info != 'ed'">
                        <label class="label_form">{{ 'modals.add_service.zone_input' | translate }}*</label>
                        <ng-select
                            formControlName="zone"
                            [items]="operatives"
                            bindLabel="zoneName"
                            bindValue="zoneId"
                            [multiple]="false"
                            class="custom_select custom_select_single input_form"
                            placeholder="{{ 'modals.add_service.zone_input_inside' | translate }}"
                        >
                        </ng-select>
                    </div>
                    <hr class="hr_divider">
                </div>
                <div>
                    <p class="p_label">
                        Tipo de servicio
                    </p>
                    <div class="form-group form-group-select2">
                        <label class="label_form">{{ 'modals.add_service.vertical_input' | translate }}</label>
                        <select formControlName="vertical" class="form-control input_form">
                            <option value="">{{ 'modals.add_service.vertical_input' | translate }}</option>
                            <option value="{{ ve }}" *ngFor="let ve of verticals">
                                {{ ('enums.verticals.'+getVerticalToTraduction(ve).toLowerCase()) | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group form-group-select">
                        <label class="label_form">{{ 'modals.add_service.product_input' | translate }}</label>
                        <ng-select
                            formControlName="product"
                            [items]="productsName"
                            [multiple]="false"
                            class="custom_select custom_select_single input_form"
                            placeholder="{{ 'modals.add_service.product_input' | translate }}"
                        >
                        </ng-select>
                    </div>
                    <div>
                        <div class="form-group form-group-select" *ngIf="show_provider === true && providersName.length > 0">
                            <label class="label_form">{{ 'modals.add_service.provider_input' | translate }}</label>
                            <ng-select
                                formControlName="provider"
                                [items]="providersName"
                                [multiple]="false"
                                class="custom_select custom_select_single input_form"
                                placeholder="{{ 'modals.add_service.provider_input' | translate }}"
                            >
                            </ng-select>
                        </div>
                        <div class="form-group form-group-select" *ngIf="show_provider === true && providersName.length < 1">
                            <label class="label_form">{{ 'modals.add_service.provider_input' | translate }}</label>
                            <ng-select
                                formControlName="provider"
                                [items]="providersNameAlt"
                                [multiple]="false"
                                class="custom_select custom_select_single input_form"
                                placeholder="{{ 'modals.add_service.provider_input' | translate }}"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="active_configuration && type_info != 'ed'">
                        <p class="">
                            <b>Configuración de servicio</b>
                        </p>
                    </div>
                    <div class="form-group" *ngIf="active_configuration == 'ITV' && type_info != 'ed'">
                        <label class="label_form">Código de cita</label>
                        <tui-input formControlName="AppointmentCode" class="input_form" [tuiTextfieldLabelOutside]="true">
                            Código de cita
                            <input
                                tuiTextfield
                                type="text"
                            />
                        </tui-input>
                    </div>
                    <div class="row" *ngIf="active_configuration == 'ITV' && type_info != 'ed'">
                        <div class="col check_div check_right">
                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="IsInspectionPaid" size="m"></tui-checkbox> ¿Tasas pagadas?
                        </div>
                    </div>
                    <div class="form-group form-group-select" *ngIf="active_configuration == 'Repostaje' && type_info != 'ed'">
                        <label class="label_form">Tipo de combustible</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="VehicleFuelType"
                            class="input_form"
                            [tuiTextfieldLabelOutside]="true"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_vf"
                        >
                            Combustible
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="VehicleFuelAlt"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                    <div class="form-group form-group-select" *ngIf="active_configuration == 'Repostaje' && type_info != 'ed'">
                        <label class="label_form">Importe:</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="MonetaryAmount"
                            class="input_form"
                            [tuiTextfieldLabelOutside]="true"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_ma"
                        >
                            Importe
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="monetarys"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                    <div class="row" *ngIf="active_configuration == 'Neumáticos' && type_info != 'ed'">
                        <div class="col-6">
                            <label class="label_form">Anchura</label>
                            <tui-input-number formControlName="Width" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Anchura
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input-number>
                        </div>
                        <div class="col-6">
                            <label class="label_form">Índice de velocidad</label>
                            <tui-input-number formControlName="SpeedIndex" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Índice de velocidad
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input-number>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Índice de carga</label>
                            <tui-input-number formControlName="LoadIndex" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Índice de carga
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input-number>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Temporada</label>
                            <tui-input formControlName="Season" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Temporada
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Marca preferida</label>
                            <tui-input formControlName="FavoriteBrand" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Marca preferida
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Modelo</label>
                            <tui-input formControlName="Model" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Modelo
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Tipo de alcance</label>
                            <tui-input formControlName="RangeType" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Tipo de alcance
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Número de neumáticos</label>
                            <tui-input-number formControlName="NumberOfTyres" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Número de neumáticos
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input-number>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col check_div check_right">
                                    <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="IsAntiPuncture" size="m"></tui-checkbox> Antipinchazos
                                </div>
                            </div>
                            <div class="row">
                                <div class="col check_div check_right">
                                    <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="TyresPermutation" size="m"></tui-checkbox> Permutación
                                </div>
                            </div>
                            <div class="row">
                                <div class="col check_div check_right">
                                    <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="TyresAlignment" size="m"></tui-checkbox> Alineación
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Precio total</label>
                            <tui-input-number formControlName="TyresPrice" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Precio total
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input-number>
                        </div>
                        <div class="col-6">
                            <br>
                            <label class="label_form">Costes laboratorio</label>
                            <tui-input-number formControlName="LabourCosts" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Costes laboratorio
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input-number>
                        </div>
                    </div>
                    <div class="form-group form-group-select" *ngIf="active_configuration == 'Grúa' && type_info != 'ed'">
                        <label class="label_form">Estado del vehículo</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="VehicleStatus"
                            class="input_form"
                            [tuiTextfieldLabelOutside]="true"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_vs"
                        >
                            Estado del vehículo
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="vehicle_status"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                    <hr class="hr_divider">
                </div>
                <div>
                    <p class="p_label">
                        Contacto
                    </p>
                    <br>
                </div>
                <div class="row origin_contact">
                    <div class="form-group">
                        <p class="fs_15">
                            {{ 'modals.add_service.origin_contact_section' | translate }}
                        </p>
                    </div>
                    <div class="row">
                        <div class="col check_div">
                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="isOriginContactExternal" size="m"></tui-checkbox>{{ 'modals.add_service.extern_origin_contact_input' | translate }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.origin_contact_name_input' | translate }}*</label>
                        <tui-input formControlName="originContactName" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.origin_contact_name_input' | translate }}
                        </tui-input>
                    </div>
                    <div class="form-group">
                        <div class="col-12">
                            <label class="label_form">{{ 'modals.add_service.origin_contact_phone_input' | translate }}*</label>
                            <tui-input-phone-international
                                formControlName="origingContactPhoneNumber"
                                [countries]="countriesPhone"
                                [(countryIsoCode)]="countryIsoCode"
                                class="input_form2"
                                [tuiTextfieldLabelOutside]="true"
                                (click)="onSelectClick($event)"
                                (focusedChange)="onSelectBlur($event)"
                                nativeId="select_op"
                              ></tui-input-phone-international>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.origin_contact_email_input' | translate }}*</label>
                        <tui-input formControlName="originContactEmailAddress" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.origin_contact_email_input' | translate }}
                        </tui-input>
                    </div>
                    <div class="row">
                        <div class="col check_div">
                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="sameContactOriginDestiny" size="m"></tui-checkbox> <span>El contacto de destino es el mismo que el de origen</span>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row destiny_contact">
                    <div class="form-group">
                        <p class="fs_15">
                            {{ 'modals.add_service.destiny_contact_section' | translate }}
                        </p>
                    </div>
                    <div class="row">
                        <div class="col check_div">
                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="isDestinationContactExternal" size="m"></tui-checkbox>{{ 'modals.add_service.extern_destiny_contact_input' | translate }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.destiny_contact_name_input' | translate }}*</label>
                        <tui-input formControlName="destinationContactName" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.destiny_contact_name_input' | translate }}
                        </tui-input>
                    </div>
                    <div class="form-group">
                        <div class="col-12">
                            <label class="label_form">{{ 'modals.add_service.destiny_contact_phone_input' | translate }}*</label>
                            <tui-input-phone-international
                                formControlName="destinationContactPhoneNumber"
                                [countries]="countriesPhone"
                                [(countryIsoCode)]="countryIsoCode"
                                class="input_form2"
                                [tuiTextfieldLabelOutside]="true"
                                (click)="onSelectClick($event)"
                                (focusedChange)="onSelectBlur($event)"
                                nativeId="select_dt"
                              ></tui-input-phone-international>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.destiny_contact_email_input' | translate }}*</label>
                        <tui-input formControlName="destinationContactEmailAddress" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.destiny_contact_email_input' | translate }}
                        </tui-input>
                    </div>
                </div>
                <div>
                    <hr class="hr_divider">
                     <p class="p_label">
                        Información adicional
                    </p>
                    <div class="form-group">
                        <label class="label_form">{{ 'modals.add_service.comments_input' | translate }}</label>
                        <tui-input formControlName="comments" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_service.comments_input' | translate }}
                            <input
                                tuiTextfield
                                type="text"
                            />
                        </tui-input>
                        <br>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            {{ 'modals.add_service.close_modal_text' | translate }}
        </button>
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_footer"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!contactForm.valid" [attr.disabled]="!contactForm.valid ? true : null" *ngIf="!contactForm.valid">
            <span *ngIf="type_info == 'du'">
                {{ 'modals.add_service.duplicate_button_text' | translate }}
            </span>
            <span *ngIf="type_info == 'ed'">
                {{ 'modals.add_service.edit_button_text' | translate }}
            </span>
            <span *ngIf="type_info == 'ad'">
                {{ 'modals.add_service.add_button_text' | translate }}
            </span> Servicio
        </button>
        <button tuiButton type="button" (click)="saveContactInfo()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m"  icon="tuiIconPlusCircleLarge" appearance="primary-button" *ngIf="contactForm.valid">
            <span *ngIf="type_info == 'du'">
                {{ 'modals.add_service.duplicate_button_text' | translate }}
            </span>
            <span *ngIf="type_info == 'ed'">
                {{ 'modals.add_service.edit_button_text' | translate }}
            </span>
            <span *ngIf="type_info == 'ad'">
                {{ 'modals.add_service.add_button_text' | translate }}
            </span> Servicio
        </button>
        <div class="loader_inside_modal_button">
            <img src="/assets/logo-loading.gif" class="img_loader_inside_modal_button" alt="">
        </div>
    </div>
    <br>
</div>