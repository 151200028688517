import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  TuiTableModule,
  TuiSortByDirective,
  TuiSortableDirective,
} from '@taiga-ui/addon-table';
import {
  TuiAvatarModule,
  TuiCellModule,
  TuiStatusModule,
  TuiIconModule,
  TuiInitialsModule,
  TuiAutoColorModule,
} from '@taiga-ui/experimental';
import {
  TuiBadgeModule,
  TuiItemsWithMoreModule,
  TuiRadioListModule,
  TuiRadioBlockModule,
  TuiPaginationModule,
  TuiCheckboxModule,
  TuiActionModule,
  TuiMarkerIconModule,
  TuiInputModule,
  TuiDataListWrapperModule,
  TuiInputDateModule,
  TUI_DATE_RANGE_VALUE_TRANSFORMER,
  TUI_DATE_VALUE_TRANSFORMER,
  TuiTextAreaModule,
  TuiSelectModule,
  TuiInputTimeModule,
  TuiInputPhoneInternationalModule,
  TuiInputNumberModule,
  TuiFilterByInputPipeModule,
  TuiStringifyPipeModule,
  TuiStringifyContentPipeModule,
  TuiComboBoxModule,
  TuiInputPasswordModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import {
  TUI_DATE_FORMAT,
  TUI_DATE_SEPARATOR,
  TuiLetModule,
  TuiDay,
} from '@taiga-ui/cdk';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiHintModule,
  TuiLoaderModule,
} from '@taiga-ui/core';
import {
  TuiCurrencyPipeModule,
  TuiFormatCardModule,
  TuiInputCVCModule,
  TuiInputCardGroupedModule,
  TuiInputCardModule,
  TuiInputExpireModule,
  TuiMoneyModule,
  TuiThumbnailCardModule,
} from '@taiga-ui/addon-commerce';
import { TuiForModule } from '@taiga-ui/cdk';
import { TuiButtonModule } from '@taiga-ui/core';
import { RouterModule } from '@angular/router';
import { GanttComponent } from './gantt/gantt.component';
import { ServiceDataModalComponent } from './service-data-modal/service-data-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AssignerTableComponent } from './assigner-table/assigner-table.component';
import { TemporalAssignerDataTableComponent } from './temporal-assigner-data-table/temporal-assigner-data-table.component';
import { MapaComponent } from './mapa/mapa.component';
import { ModalAddAssignamentComponent } from './modal-add-assignament/modal-add-assignament.component';
import {
  NgbDropdownModule,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TuiNotificationModule } from '@taiga-ui/core';

import { ProvidersTableComponent } from './providers-table/providers-table.component';
import { ModalAddProviderComponent } from './modal-add-provider/modal-add-provider.component';
import { ClientsTableComponent } from './clients-table/clients-table.component';
import { ModalAddClientComponent } from './modal-add-client/modal-add-client.component';

import { PendingServicesComponent } from './pending-services/pending-services.component';
import { IncidentsComponent } from './incidents/incidents.component';
import { ServiceHistoryComponent } from './service-history/service-history.component';
import { TuiInputDateRangeModule } from '@taiga-ui/kit';
import {
  ExampleDateTransformer,
  getExampleDateRangeTransformer,
} from './service-history/value.transformers';

import { PlatformBlockerTableComponent } from './platform-blocker-table/platform-blocker-table.component';

import { EditDetailContactServiceComponent } from './edit-detail-contact-service/edit-detail-contact-service.component';
import { ModalDuplicateServiceComponent } from './modal-duplicate-service/modal-duplicate-service.component';
import { TuiDataListModule } from '@taiga-ui/core';
import { ModalAddPersonalComponent } from './modal-add-personal/modal-add-personal.component';
import { ModalAddAssignmentComponent } from './modal-add-assignment/modal-add-assignment.component';
import { EventsTableComponent } from './events-table/events-table.component';
import { ModalAddBlockComponent } from './modal-add-block/modal-add-block.component';
import { TranslateEnumPipe } from '../pipes/translate-enum.pipe';
import { ModalAssignDriverComponent } from './modal-assign-driver/modal-assign-driver.component';
import { PricesTableComponent } from './prices-table/prices-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalAssignProviderComponent } from './modal-assign-provider/modal-assign-provider.component';
import { ModalGestionNodeComponent } from './modal-gestion-node/modal-gestion-node.component';
import { ModalAddEventOrIncidenceComponent } from './modal-add-event-or-incidence/modal-add-event-or-incidence.component';
import { ModalSendMessageComponent } from './modal-send-message/modal-send-message.component';
import { EditClientPriceModalComponent } from './edit-client-price-modal/edit-client-price-modal.component';
@NgModule({
  declarations: [
    NavbarComponent,
    GanttComponent,
    ServiceDataModalComponent,
    AssignerTableComponent,
    TemporalAssignerDataTableComponent,
    MapaComponent,
    ModalAddAssignamentComponent,

    PendingServicesComponent,
    IncidentsComponent,

    ProvidersTableComponent,
    ModalAddProviderComponent,
    ClientsTableComponent,
    ModalAddClientComponent,
    ModalAddEventOrIncidenceComponent,

    ProvidersTableComponent,
    ModalAddProviderComponent,
    PendingServicesComponent,
    IncidentsComponent,
    ServiceHistoryComponent,
    PlatformBlockerTableComponent,
    EditDetailContactServiceComponent,
    ModalDuplicateServiceComponent,
    ModalAddPersonalComponent,
    ModalAddAssignmentComponent,
    EventsTableComponent,
    ModalAddBlockComponent,
    TranslateEnumPipe,
    ModalAssignDriverComponent,
    PricesTableComponent,
    ModalAssignProviderComponent,
    ModalSendMessageComponent,
    ModalGestionNodeComponent,
    EditClientPriceModalComponent,
  ],
  imports: [
    CommonModule,
    TuiLetModule,
    TuiTableModule,
    TuiItemsWithMoreModule,
    TuiBadgeModule,
    TuiAvatarModule,
    FormsModule,
    ReactiveFormsModule,
    TuiRadioListModule,
    TuiRadioBlockModule,
    TuiCellModule,
    TuiStatusModule,
    TuiLinkModule,
    TuiIconModule,
    TuiInitialsModule,
    TuiCurrencyPipeModule,
    TuiFormatCardModule,
    TuiInputCVCModule,
    TuiInputCardGroupedModule,
    TuiInputCardModule,
    TuiInputExpireModule,
    TuiMoneyModule,
    TuiThumbnailCardModule,
    TuiAutoColorModule,
    TuiForModule,
    TuiButtonModule,
    TuiPaginationModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiCheckboxModule,
    TuiActionModule,
    TuiMarkerIconModule,
    TuiInputModule,
    TuiSvgModule,
    RouterModule,
    TuiDataListWrapperModule,
    ModalModule.forRoot(),
    NgbDropdownModule,
    TuiInputDateModule,
    NgbDatepickerModule,
    TuiNotificationModule,
    TuiTextfieldControllerModule,
    TuiInputDateRangeModule,
    TuiTextAreaModule,
    TuiTextfieldControllerModule,
    TuiInputDateRangeModule,
    TuiDataListModule,
    TuiSelectModule,
    TuiInputTimeModule,
    TuiInputPhoneInternationalModule,
    TuiHintModule,
    TuiInputNumberModule,
    TuiFilterByInputPipeModule,
    TuiStringifyPipeModule,
    TuiStringifyContentPipeModule,
    TuiComboBoxModule,
    TuiInputPasswordModule,
    TuiLoaderModule,
    NgSelectModule,
    TranslateModule.forChild(),
    TuiToggleModule,
  ],

  exports: [
    NavbarComponent,
    GanttComponent,
    ServiceDataModalComponent,
    AssignerTableComponent,
    TemporalAssignerDataTableComponent,
    MapaComponent,
    ModalAddAssignamentComponent,

    ProvidersTableComponent,
    ModalAddProviderComponent,
    ModalAddEventOrIncidenceComponent,
    ClientsTableComponent,
    ModalAddClientComponent,
    PlatformBlockerTableComponent,
    PendingServicesComponent,
    IncidentsComponent,
    ServiceHistoryComponent,
    ModalAddPersonalComponent,
    ModalAddAssignmentComponent,
    EventsTableComponent,
    ModalAddBlockComponent,
    PricesTableComponent,
    TranslateModule,
    TranslateEnumPipe,
    EditClientPriceModalComponent,
  ],
  providers: [
    { provide: TUI_DATE_FORMAT, useValue: 'DMY' },
    { provide: TUI_DATE_SEPARATOR, useValue: '/' },
    {
      provide: TUI_DATE_VALUE_TRANSFORMER,
      useClass: ExampleDateTransformer,
    },
    {
      provide: TUI_DATE_RANGE_VALUE_TRANSFORMER,
      deps: [TUI_DATE_VALUE_TRANSFORMER],
      useFactory: getExampleDateRangeTransformer,
    },
    ProvidersTableComponent,
    ModalAddProviderComponent,
    ModalAddEventOrIncidenceComponent,
    ModalAddClientComponent,
    TuiSortByDirective,
    TuiSortableDirective,
  ],
})
export class SharedModule {}
