<div class="modal-all">
  <div class="modal-header">
    <!-- <h4 class="modal-title pull-left" *ngIf="type_info == 'whatsapp'">{{ 'modals.add_service.duplicate_title_text' | translate }}</h4>
        <h4 class="modal-title pull-left" *ngIf="type_info == 'sms'">{{ 'modals.add_service.edit_title_text' | translate }}</h4> -->
    <h4 class="modal-title pull-left" *ngIf="send_type == 'WhatsApp'">
      Enviar WhatsApp
    </h4>
    <h4 class="modal-title pull-left" *ngIf="send_type == 'SMS'">Enviar SMS</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="closeCanvas()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="modal_duplicate_service"></div>

    <div id="modal_duplicate_service_alert"></div>
    <span style="padding-left: 10px"
      >Hay {{ getNumSelectedServices }} servicios seleccionados:</span
    >
    <div
      class="mt-1"
      *ngFor="let service of servicios_seleccionados; let i = index"
      [style.background]="
        service.deleted ? '#A60B0B1F' : i % 2 == 0 ? 'white' : '#F5F7F9'
      "
    >
      <div class="service d-flex flex-column gap-2" style="padding: 10px">
        <div class="d-flex" style="color: #4d535a">
          <span
            style="font-size: 15px; font-weight: 400; color: black"
            [style.color]="service.deleted ? '#A60B0B' : 'black'"
            >{{ service.orderHash }}</span
          >
          <span
            style="
              background-color: #e1e6ec;
              border-radius: 5px;
              margin-left: auto;
              padding: 2px 5px;
              color: black;
            "
            >Recogida externo</span
          >
        </div>
        <div
          class="d-flex"
          style="border-left: 1px solid gray; padding-left: 5px"
        >
          <span style="color: #4d535a">Cliente: {{ service.clientName }}</span>
          <span style="margin-left: auto; color: black"
            ><b>{{ service.externalContactPhoneNumber }}</b></span
          >
        </div>
        <div style="color: #4d535a">Fecha: {{ service.formattedDate }}</div>
        <div style="color: #4d535a">Matrícula: {{ service.licensePlate }}</div>
        <div style="color: #4d535a">Hora: {{ service.startHour }}</div>
        <div style="color: #4d535a">Zona: {{ service.zoneName }}</div>
        <div style="color: #4d535a">
          Tipo de producto: {{ service.mainProductName }}
        </div>
        <div class="d-flex" style="color: #4d535a">
          <div>
            Topping:
            <span *ngFor="let topping of service.toppingNames">{{
              topping
            }}</span>
          </div>
          <div style="margin-left: auto">
            <button
              tuiButton
              type="button"
              class="tui-space_right-3 tui-space_bottom-3"
              size="s"
              appearance="outline"
              (click)="deleteService(service)"
              *ngIf="!service.deleted"
            >
              <!-- {{ "modals.add_service.close_modal_text" | translate }} -->
              Eliminar
            </button>
            <a
              tuiLink
              *ngIf="service.deleted"
              (click)="undoDeleteService(service)"
              >Deshacer</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer"
      size="m"
      appearance="secondary"
      (click)="closeCanvas()"
    >
      {{ "modals.add_service.close_modal_text" | translate }}
    </button>
    <button
      tuiButton
      type="button"
      (click)="sendMessage()"
      class="tui-space_right-3 tui-space_bottom-3 but_footer"
      size="m"
      appearance="primary-button"
    >
      <span>
        <!-- {{ 'modals.add_service.duplicate_button_text' | translate }} -->
        Enviar
      </span>
    </button>
    <div class="loader_inside_modal_button">
      <img
        src="/assets/logo-loading.gif"
        class="img_loader_inside_modal_button"
        alt=""
      />
    </div>
  </div>
  <br />
</div>
