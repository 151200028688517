<!-- Modal -->
<div id="myModal" class="modal-content">
  <div class="modal-header-custom d-flex justify-content-between">
    <div>
      <div class="service-data">
        <span class="service-name"></span>
        <span class="service-name"> Editar Tasa Movilidad ITV Coche </span>

        <div class="display-contents">
          <span class="service-hash"
            >Modifica el precio cliente y la disponibilidad para contratación
          </span>
        </div>
      </div>
    </div>
    <div></div>
    <button
      type="button"
      class="btn-close"
      (click)="bsModalRef?.hide()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body-custom mt-4">
    <div class="client-data align-items-center mb-1">
      <form [formGroup]="disponibility">
        <div class="d-flex gap-2">
          <tui-toggle formControlName="isVailable" size="l"></tui-toggle>
          <label class="service-hash">Disponible en contratación</label>
        </div>

        <div class="mt-4">
          <div class="d-flex gap-1">
            <p class="font-weight-bolder">Precio general:</p>
            <span>13€ (Tarifa B2B)</span>
          </div>
          <p>
            <tui-input formControlName="testValue1">
              Precio cliente
              <input inputmode="numeric" tuiTextfield />
            </tui-input>
          </p>
          <span class="service-discount mt-1">23% de descuento </span>
        </div>
      </form>
    </div>

    <div class="modal-footer-custom">
      <button class="cancel-button-menu mt-3">Salir sin guardar</button>
      <button class="export-button-menu mt-3">Guardar</button>
    </div>
  </div>
</div>
